import React from 'react';
import { useHistory, NavLink } from 'react-router-dom';

import {ReactComponent as  Ic_dashboard}  from '../../../assets/images/ic_dashboard.svg';
import {ReactComponent as  Ic_order}  from '../../../assets/images/ic_orders.svg';
import {ReactComponent as  Ic_admin}  from '../../../assets/images/ic_admin.svg';

const HeaderNav = props => {
    return (
        <div className={props.className}>
					<ul>
						<li>
							<NavLink to='/home'><Ic_dashboard />dashboard</NavLink>
						</li>
						<li>
							<NavLink to='/users'><Ic_order />Orders</NavLink>
						</li>
						<li>
							<NavLink to='/admin'><Ic_admin />Admin</NavLink>
						</li>
						{/* <li>
							<NavLink to='/dashboard'><Ic_wallet />Co-Op<span className='icon-status'>1</span></NavLink>
						</li> */}
                        {props.children}
					</ul>
					
				</div>
    )
}

export default HeaderNav;