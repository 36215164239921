import React from 'react';
import {Formik, Field} from 'formik';

import './clinic.css'

const Clinic = (props) => {
    const initialValues = {
        businessName: '',
        clinicName: '',
        reportType: '',
        reportTo: '',
        isActive: false
    }
    return (
        <>
            
            <div className="tab-content">
                <div className="form-main">
                    <Formik
                        enableReinitialize={true}
                        initialValues={initialValues}
                        onSubmit={(values) => console.log(values)}
                    >
                        {({handleSubmit, values, setFieldValue}) => {
                            return (
                                
                                <form onSubmit={handleSubmit}>
                                    <div className="form_field">
                                        <div className="field-left">
                                            <label>Business Name</label>
                                        </div>
                                        <div className="field-right">
                                            <Field type="text" placeholder="Enter business name" name="businessName" />
                                        </div>
                                    </div>
                                    <div className="form_field">
                                        <div className="field-left">
                                            <label>Clinic Name</label>
                                        </div>
                                        <div className="field-right">
                                            <Field type="text" placeholder="Enter clinic name" name="clinicName" />
                                        </div>
                                    </div>
                                    <div className="form_field">
                                        <div className="field-left">
                                            <label>Report Type</label>
                                        </div>
                                        <div className="field-right">
                                            <select name="reportType" value={values.reportType} onChange={(e) => {
                                                setFieldValue('reportType', e.target.value)
                                            }}>
                                                <option>Select</option>
                                                <option value="type1">Type 1</option>
                                                <option value="type2">Type 2</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form_field">
                                        <div className="field-left">
                                            <label>Report To</label>
                                        </div>
                                        <div className="field-right">
                                            <select name="reportTo" value={values.reportTo} onChange={(e) => {
                                                setFieldValue('reportTo', e.target.value)
                                            }}>
                                                <option>Select</option>
                                                <option value="type1">Type 1</option>
                                                <option value="type2">Type 2</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form_field">
                                        <div className="field-left">
                                            <label>Is Active ?</label>
                                        </div>
                                        <div className="field-right">
                                            <div className="custom-radio">
                                                <input type="radio" name="isActive" id="yes-text" defaultChecked={!!values.isActive} onClick={() => setFieldValue("isActive", true)} />
                                                    <label htmlFor="yes-text">Yes</label>
                                            </div>
                                            <div className="custom-radio">
                                                <input type="radio" name="isActive" id="no-text" defaultChecked={!values.isActive} onClick={() => setFieldValue("isActive", false)} />
                                                <label htmlFor="no-text">No</label>
                                            </div>
                                                </div>
                                            </div>
                                    <div className="form-submit">
                                        <div className="button-field">
                                            <input type="submit" name="next" value="next" />
                                        </div>
                                    </div>
                                </form>
                            
                            )
                        }}
                    </Formik>
                </div>
                   
                </div>
           
        </>
    )
}

export default Clinic
