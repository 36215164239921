import React from 'react'

import './footer.css';

const Footer = (props) => {
    return (
        <footer className="site-footer"> 
            <div className='footer-left'>
                <p>Copyright © 2020 <a href="#" title="CoBrand Media">CoBrand Media</a>. All Rights Reserved.</p>
            </div>
            <div className='footer-right'>
                <ul>
                    <li><a href="#" title="Terms of Use">Terms of Use</a></li>
                    <li><a href="#" title="Privacy Policy">Privacy Policy</a></li>
                    <li><a href="#" title="Contact Us">Contact Us</a></li>
                </ul>
            </div>
        </footer>
    )
}


export default Footer