import React from 'react';
import {Formik, Field} from 'formik';
import close_ic from '../../../assets/images/close-ic.svg';



const AddressForm = props => {
    const initialValues = {
		addressName: "",
		address1: "",
		address2: "",
		postCode: "",
		country: "",
		state: "",
		city: "",
		email: "",
		businessPhone: "",
		businessEmail: "",
		website: "",
		facebookUrl: "",
		instagramUrl: "",
	}
    return (
        <div className="popup-box data-popup-box">
            <div className="box-heading">
                <h2 className="h1">Add Address/User</h2>
                <button className="close-btn"><img src={close_ic} alt="icon" onClick={props.closeModal} /></button>
            </div>
            <div className="box-content">
               
                    <Formik
                    enableReinitialize={true}
                    initialValues={initialValues}
                    onSubmit={(values) => {console.log(values)}}
                    >
                        {({handleSubmit, values, setFieldValue}) => {
                            return (
                                <form onSubmit={handleSubmit}>
                                    <div className="form_field">
                                        <div className="field-left">
                                            <label>Address Name</label>
                                        </div>
                                        <div className="field-right">
                                            <Field type="text" placeholder="Enter address" name="addressName" />
                                        </div>
                                    </div>
                                    <div className="form_field">
                                        <div className="field-left">
                                            <label>Address 1</label>
                                        </div>
                                        <div className="field-right">
                                            <Field type="text" placeholder="Address 1" name="address1" />
                                        </div>
                                    </div>
                                    <div className="form_field">
                                        <div className="field-left">
                                            <label>Address 2</label>
                                        </div>
                                        <div className="field-right">
                                            <Field type="text" placeholder="Address 2" name="address2" />
                                        </div>
                                    </div>
                                    <div className="form_field">
                                        <div className="field-left">
                                            <label>Postcode</label>
                                        </div>
                                        <div className="field-right">
                                            <Field type="text" placeholder="Postcode" name="postcode" />
                                        </div>
                                    </div>
                                    <div className="form_field">
                                        <div className="field-left">
                                            <label>Country</label>
                                        </div>
                                        <div className="field-right">
                                            <select name='country' defaultValue={values.country} onChange={(e) =>  {setFieldValue('country',e.target.value);
                                            setFieldValue('state','')
                                        }}>
                                                <option>Select Country</option>
                                                {
                                                    ['Usa', 'India'].map((obj, index) => {
                                                    return <option value={obj} key={index}>{obj}</option>
                                                    })
                                                }
                                                
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form_field">
                                        <div className="field-left">
                                            <label>State</label>
                                        </div>
                                        <div className="field-right">
                                            <select name='state' defaultValue={values.state} onChange={(e) =>  {setFieldValue('state',e.target.value);
                                            setFieldValue('city','')
                                        }}>
                                                <option>Select State</option>
                                                {
                                                    ['Gujarat', 'Maharashtra'].map((obj, index) => {
                                                    return <option value={obj} key={index}>{obj}</option>
                                                    })
                                                }
                                                
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form_field">
                                        <div className="field-left">
                                            <label>City</label>
                                        </div>
                                        <div className="field-right">
                                            <select name='city' defaultValue={values.state} onChange={(e) =>  {setFieldValue('city',e.target.value);
                                            
                                        }}>
                                                <option>Select City</option>
                                                {
                                                    ['Ahmedbad', 'Surat'].map((obj, index) => {
                                                    return <option value={obj} key={index}>{obj}</option>
                                                    })
                                                }
                                                
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form_field">
                                        <div className="field-left">
                                            <label>Email Address</label>
                                        </div>
                                        <div className="field-right">
                                            <Field type="text" placeholder="name@domain.com" name="email" />
                                        </div>
                                    </div>
                                    <div className="form_field">
                                        <div className="field-left">
                                            <label>Business Phone</label>
                                        </div>
                                        <div className="field-right">
                                            <Field type="text" placeholder="1234567890" name="businessPhone" />
                                        </div>
                                    </div>
                                    <div className="form_field">
                                        <div className="field-left">
                                            <label>Business Email Id</label>
                                        </div>
                                        <div className="field-right">
                                            <Field type="text" placeholder="name@domain.com" name="businessEmail" />
                                        </div>
                                    </div>
                                    <div className="form_field">
                                        <div className="field-left">
                                            <label>Website</label>
                                        </div>
                                        <div className="field-right">
                                            <Field type="text" placeholder="https://www.abc.com" name="website" />
                                        </div>
                                    </div>
                                    <div className="form_field">
                                        <div className="field-left">
                                            <label>Facebook Url</label>
                                        </div>
                                        <div className="field-right">
                                            <Field type="text" placeholder="https://www.facebook.com/username" name="facebookUrl" />
                                        </div>
                                    </div>
                                    <div className="form_field">
                                        <div className="field-left">
                                            <label>Instagram Url</label>
                                        </div>
                                        <div className="field-right">
                                            <Field type="text" placeholder="https://.facebook.com/username" name="instagramUrl" />
                                        </div>
                                    </div>
                                    <div className="form-submit">
                                        <div className="button-field">
                                            <input type="submit" value="ADD address" name="address" />
                                        </div>
                                        <div className="button-field disable-btn">
                                            <input type="button" value="close" name="close" onClick={() => props.closeModal()}/>
                                        </div>
                                    </div>
                                </form>
                            )
                        }}

                    </Formik>
              
            </div>
        </div>
        
    )
};

export default AddressForm;