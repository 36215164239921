import React, { Component, createRef } from 'react';

import Header from '../../features/header/component/header';
import Sidebar from '../../features/sidebar/component/sidebar';

import { setRef } from '../../shared/services/reference-service';
import Footer from '../../features/footer/component/footer';
import HeaderNav from '../../shared/components/header-nav/headerNav';

/**
 * Layout - Base layout of application
 */
class Layout extends Component {
	state = {
		innerWidth: window.innerWidth,
		sidebarOpen: false
	};
	constructor(props) {
		super(props);
		this.containerRef = createRef();
	}

	componentDidMount = () => {
		if (this.containerRef.current) {
			setRef(this.containerRef);
		}

		window.addEventListener('resize', this.handleResize);
	};

	componentWillUnmount = () => {
		window.removeEventListener('resize', this.handleResize);
	};

	render() {
		return (
			<div className='app-container'>
				<div className='app-main-container'>
					<div className='app-header'>
						<Header
							innerWidth={this.state.innerWidth}
							handleMenu={this.handleMenuClick}
						/>
					</div>

					<main
						ref={this.containerRef}
						className='app-main-content-wrapper'>
						<div className='app-main-content'>
							<div className={`app-wrapper main-sec-wrap ${this.state.sidebarOpen ? 'active-main' : ''}`}>
								<div className='app-sidebar'>
									<Sidebar
										innerWidth={this.state.innerWidth}
										isSidebarOpen={this.state.sidebarOpen}
										closeSidebar={this.handleMenuClick}
									/>
								</div>
								<div className="main-content" onClick={(e) => {
									e.stopPropagation();
									if(this.state.innerWidth <= 991) {
										this.handleMainClick()
									}
									}}>
									{this.props.children}
								</div>
								
								<section className="bootom-sec">
								<HeaderNav className="header-nav" />
								
								<Footer />
								</section>
							</div>
						</div>
					</main>
					
				</div>
			</div>
		);
	}

	handleResize = () => {
		this.setState({
			innerWidth: window.innerWidth,
		});
	};

	/**
	 * handleMenuClick method is used to toggle menu
	 */
	handleMenuClick = () => {
		this.setState({
			sidebarOpen: !this.state.sidebarOpen
		}, () => {
			
			if(this.state.sidebarOpen) {
				document.querySelector("body").classList.add("sidebar")
			} else {
				document.querySelector("body").classList.remove("sidebar")
			}
		})
	};


	/**
	 * handleMainclick every time whenever user click somewhere in body
	 * it will verify that if sidebar is open than close it
	 */
	handleMainClick = () =>{
		if(this.state.sidebarOpen) {
			this.setState({
				sidebarOpen: false
			})
			document.querySelector("body").classList.remove("sidebar")
		}
	}
}

export default Layout;
