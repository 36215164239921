import React from 'react';
import { Link } from 'react-router-dom';
import { Formik, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';
import Modal from '../../../shared/components/modal/modal';
import Slider from '../../../shared/components/slider/slick-slider';
import authService from '../../../shared/services/auth-service'

import VerifyOtp from '../component/verify-otp';
import ForgotPassword from '../component/forgot-password';
import logo from '../../../assets/images/logo.png';
import bg_pattern from '../../../assets/images/bg-pattern.svg';
import slider_img from '../../../assets/images/slider-img.png';
 
import '../component/login.css';

var sectionStyle = {
	backgroundImage: `url(${bg_pattern})`,
};

class LoginContainer extends React.Component {
	state = {
		isModalOpen: false,
		tag: '',
	};
	render() {
		return (
			<>
				<section className='login_page' style={sectionStyle}>
					<div className='main'>
						<div className='inner-screen'>
							<div className='left-column'>
								<div className='logo'>
									<Link to='/'>
										<img
											src={logo}
											className='App-logo'
											alt='logo'
										/>
									</Link>
								</div>
								<div className='middle-content'>
									<h1>Login</h1>
									<Formik
										initialValues={{
											username: '',
											password: '',
										}}
										validationSchema={validateLoginForm}
										validateOnMount={false}
										onSubmit={(values) =>
											this.handleLogin(values)
										}>
										{({
											values,
											handleSubmit,
											errors,
											touched,
										}) => (
											<form onSubmit={handleSubmit}>
												<div
													className={`form-field ${
														errors &&
														touched.username &&
														errors.username &&
														errors.username !== ''
															? 'error'
															: ''
													}`}>
													<label>Username</label>
													<div className='field_main'>
														<Field
															type='text'
															placeholder='name@domain.com'
															name='username'
															className='user-field'
														/>
														{errors &&
															touched.username &&
															errors.username &&
															errors.username !==
																'' && (
																<span className='error-msg'>
																	<ErrorMessage
																		name='username'
																		render={(
																			err
																		) => {
																			return err;
																		}}
																	/>
																</span>
															)}
													</div>
												</div>
												<div
													className={`form-field ${
														errors &&
														touched.password &&
														errors.password &&
														errors.password !== ''
															? 'error'
															: ''
													}`}>
													<label>Password</label>
													<div className='field_main'>
														<Field
															type='password'
															placeholder='******'
															name='password'
															className='pwd-field'
														/>
														{errors &&
															touched.password &&
															errors.password &&
															errors.password !==
																'' && (
																<span className='error-msg'>
																	<ErrorMessage
																		name='password'
																		render={(
																			err
																		) => {
																			return err;
																		}}
																	/>
																</span>
															)}
													</div>
												</div>
												<div className='form-field custom-checkbox'>
													<input
														type='checkbox'
														name=''
														id='checkme'
													/>
													<label htmlFor='checkme'>
														Remember me
													</label>
												</div>
												<div className='form-field button-field'>
													<input
														type='submit'
														value='Sign In'
														name='sign-in'
													/>
												</div>
											</form>
										)}
									</Formik>
								

								<div className='bottom-links'>
									<ul>
										<li>
											<button
												onClick={() =>
													this.handleOpenModal(
														'password'
													)
												}>
												Forgot Password?
											</button>
										</li>
										<li>
											<button
												onClick={() =>
													this.handleOpenModal(
														'username'
													)
												}>
												Forgot Username?
											</button>
										</li>
									</ul>
								</div>
								</div></div>
							<div className='right-column'>
								<div className='col-head'>
									<h2>Welcome to Bausch + Lomb Reach</h2>
									<p>
										A practice marketing platform with tools
										to help you reach patients through
										innovative technology
									</p>
								</div>

								<div className='image-slider'>
									<Slider>
										<div className='image-item'>
											<img
												src={slider_img}
												alt='slider-1'
											/>
										</div>
										<div className='image-item'>
											<img
												src={slider_img}
												alt='slider-2'
											/>
										</div>
										<div className='image-item'>
											<img
												src={slider_img}
												alt='slider-3'
											/>
										</div>
										<div className='image-item'>
											<img
												src={slider_img}
												alt='slider-4'
											/>
										</div>
										<div className='image-item'>
											<img
												src={slider_img}
												alt='slider-5'
											/>
										</div>
									</Slider>
								</div>
							</div>
						</div>
					</div>
				</section>
				{/* {this.state.isModalOpen && ( */}
				<Modal
					show={this.state.isModalOpen}
					handleClose={this.handleCloseModal}>
					{this.state.tag === 'username' ? (
						<ForgotPassword
							sendUserName={this.handleSendUserName}
							closeModal={this.handleCloseModal}
						/>
					) : this.state.tag !== '' ? (
						<VerifyOtp
							closeModal={this.handleCloseModal}
							verifyOtp={this.handleVerifyOtp}
						/>
					) : null}
				</Modal>
				{/* )} */}
			</>
		);
	}

	/**
	 * handleInputChange method is used to handle input change method
	 */
	handleInputChange = (name, value) => {
		this.setState({ [name]: value });
	};

	/**
	 * handle login method is called when user click on login
	 */
	handleLogin = (value) => {
		console.log(value);
		authService.setAuthData(value);
		this.props.history.push('/home')
	};

	/**
	 * handleOpenModal method is used to set modal
	 */
	handleOpenModal = (tag) => {
		this.setState({
			tag: tag,
			isModalOpen: true,
		});
	};

	/**
	 * handleSendUserName method is used to listen when user click on send username button
	 */
	handleSendUserName = (username) => {
		if (username !== '') {
			this.setState({
				isModalOpen: false,
				tag: '',
			});
		}
	};

	/**
	 * handleCloseModal method is used to close modal
	 */
	handleCloseModal = () => {
		this.setState({ isModalOpen: false });
	};

	/**
	 * handleVerifyOtp method is used when user clicks on verify otp
	 */
	handleVerifyOtp = (otp) => {
		if (otp !== '') {
			this.setState({
				isModalOpen: false,
				tag: '',
			});
		}
	};
}

const validateLoginForm = yup.object().shape({
	username: yup.string().required('Username is required'),
	password: yup.string().required('Password is required'),
});

export default LoginContainer;
