import React from "react";
import Table from "../../../shared/components/table/table";
import TableHead from "../../../shared/components/table/tableHead";
import TableRow from "../../../shared/components/table/tableRow";
import TableBody from "../../../shared/components/table/tableBody";
import TableCell from "../../../shared/components/table/tableCell";

import "react-perfect-scrollbar/dist/css/styles.css";

import PerfectScrollbar from "react-perfect-scrollbar";

import deleteIcon from "../../../assets/images/delete.svg";
import editIcon from "../../../assets/images/edit.svg";
import filtericon from "../../../assets/images/ic_feather_filter.svg";
import CustomScrollbar from "../../../shared/components/custom-scrollbar/customScrollbar";
import { withRouter } from 'react-router-dom';

const UserListComponent = (props) => {
	const list = props.list;
	const headerList = ['action',"salutation",
	"first name",
	"last name",
	"user name",
	"department",
	"position",
	"branch",	"email",
	"mobile",
	"active",
]
	return (
		<>
			<div className='table-main-wrap users-class'>
				<div className="table-link">
					<a href="#!" title="Add Address" onClick={(e) => {
						e.preventDefault();
						props.history.push('/users/add')
					}}>+ Add User</a>
				</div>
				<PerfectScrollbar style={{ maxHeight: 600, overflow: 'auto' }} component='div'>
					
						<Table className='' stickyHeader aria-label="sticky table">
							<TableHead>
								<TableRow>
									{headerList.map((obj, index) => {
										return <TableCell key={index}>{obj}</TableCell>;
									})}
								</TableRow>
								<TableRow style={{top: 57}}>
									<TableCell style={{top: 63}}>
										<span>
											<img src={filtericon} alt='delete' />
										</span>
									</TableCell>
									<TableCell style={{top: 63}}>
										<select
											onChange={(e) =>
												props.applyFilter(
													"salutation",
													e.target.value,
													"select"
												)
											}>
											<option value=''>All</option>
											<option value='Mr'>Mr</option>
											<option value='Mrs'>Mrs</option>
										</select>
									</TableCell>
									<TableCell style={{top: 63}}>
										<input
											type='text'
											name=''
											onChange={(e) =>
												props.applyFilter("firstName", e.target.value, "input")
											}
										/>
									</TableCell>
									<TableCell style={{top: 63}}>
										<input
											type='text'
											name=''
											onChange={(e) =>
												props.applyFilter("lastName", e.target.value, "input")
											}
										/>
									</TableCell>
									<TableCell style={{top: 63}}>
										<input
											type='text'
											name=''
											onChange={(e) =>
												props.applyFilter("userName", e.target.value, "input")
											}
										/>
									</TableCell>
									<TableCell style={{top: 63}}>
										<select
											onChange={(e) =>
												props.applyFilter(
													"department",
													e.target.value,
													"select"
												)
											}>
											<option value=''>All</option>
											<option value='Development'>Development</option>
											<option value='Marketing'>Marketing</option>
										</select>
									</TableCell>
									<TableCell style={{top: 63}}>
										<select
											onChange={(e) =>
												props.applyFilter("position", e.target.value, "select")
											}>
											<option value=''>All</option>
											<option value=''>All</option>
										</select>
									</TableCell>
									<TableCell style={{top: 63}}>
										<select
											onChange={(e) =>
												props.applyFilter("branch", e.target.value, "select")
											}>
											<option value=''>All</option>
											<option value=''>All</option>
										</select>
									</TableCell>
									<TableCell style={{top: 63}}>
										<input
											type='text'
											name=''
											onChange={(e) =>
												props.applyFilter("email", e.target.value, "input")
											}
										/>
									</TableCell>
									<TableCell style={{top: 63}}>
										<input
											type='text'
											name=''
											onChange={(e) =>
												props.applyFilter("mobile", e.target.value, "input")
											}
										/>
									</TableCell>
									<TableCell style={{top: 63}}>
										<select
											onChange={(e) =>
												props.applyFilter("active", e.target.value, "select")
											}>
											<option value={""}>All</option>
											<option value={"true"}>Yes</option>
											<option value={"false"}>No</option>
										</select>
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{ !!list.length ? list.map((obj, idnex) => {
									return (
										<TableRow key={idnex}>
											<TableCell>
												<span>
													{" "}
													<img src={editIcon} alt='edit' />{" "}
													<img src={deleteIcon} alt='delete' />{" "}
												</span>
											</TableCell>
											<TableCell>{obj.salutation}</TableCell>
											<TableCell>{obj.firstName}</TableCell>
											<TableCell>{obj.lastName}</TableCell>
											<TableCell>{obj.userName}</TableCell>
											<TableCell>{obj.department}</TableCell>
											<TableCell>{obj.position}</TableCell>
											<TableCell>{obj.branch}</TableCell>
											<TableCell>{obj.email}</TableCell>
											<TableCell>{obj.mobile}</TableCell>
											<TableCell
												className={`${
													obj.active === "true" ? "green" : "red"
												}`}>
												<span>{obj.active === "true" ? "Yes" : "No"}</span>
											</TableCell>
										</TableRow>
									);
								}): (
									<TableRow>
										<TableCell colSpan={headerList.length}>
												<div className="not_found"> No Data Found </div>
										</TableCell>
									</TableRow>
								)}
							</TableBody>
						</Table>
				
				</PerfectScrollbar>
			</div>
		</>
	);
};

export default withRouter(UserListComponent);
