import React from 'react';
import placeholder_img1 from '../../../../assets/images/placeholder-img.svg';
import ModalComponent from '../../../../shared/components/modal/modal';
import close_icon from '../../../../assets/images/gray-close-ic.svg';
import asset_popup_img from '../../../../assets/images/free-asset-popup.png';
import '../resource.css';
import TabComponent from '../../../../shared/components/tab/tab';
var bg_img1 = {
	backgroundImage: `url(${placeholder_img1})`,
};

const FreeAssets = (props) => {

	const tabs = ['social media' , 'videos', 'website banners', 'email headers'];
	const [tab, setTab] = React.useState(0);
	const [modal, setmodal] = React.useState(null)

	const handleTabChange = (value) => {
		setTab(value)
	}

	const cards = [{
		bgImage: bg_img1,
		info : {
			p: 'Biotrue ONEDay',
			previewImage: asset_popup_img,
			h4: 'Social Media Calendar Q2 Images & Copy',
			p1: '5.5" Wide x 8.5" Tall'
		}
	}, {
		bgImage: bg_img1,
		info : {
			p: 'Biotrue ONEDay',
			previewImage: asset_popup_img,
			h4: 'Facebook Post High Five',
			p1: '5.5" Wide x 8.5" Tall'
		}
	}, {
		bgImage: bg_img1,
		info : {
			p: 'Biotrue ONEDay',
			previewImage: asset_popup_img,
			h4: 'Facebook Post Makeup',
			p1: '5.5" Wide x 8.5" Tall'
		}
	},
	{
		bgImage: bg_img1,
		info : {
			p: 'Biotrue ONEDay',
			previewImage: asset_popup_img,
			h4: 'Facebook Post Showers',
			p1: '5.5" Wide x 8.5" Tall'
		}
	},
	{
		bgImage: bg_img1,
		info : {
			p: 'Biotrue ONEDay',
			previewImage: asset_popup_img,
			h4: 'Facebook Post Sunscreen',
			p1: '5.5" Wide x 8.5" Tall'
		}
	},
	{
		bgImage: bg_img1,
		info : {
			p: 'Biotrue ONEDay',
			previewImage: asset_popup_img,
			h4: 'Facebook Post Makeup',
			p1: '5.5" Wide x 8.5" Tall'
		}
	},
	{
		bgImage: bg_img1,
		info : {
			p: 'Biotrue ONEDay',
			previewImage: asset_popup_img,
			h4: 'Facebook Post Travel',
			p1: '5.5" Wide x 8.5" Tall'
		}
	},
	{
		bgImage: bg_img1,
		info : {
			p: 'Biotrue ONEDay',
			previewImage: asset_popup_img,
			h4: 'Facebook Post Vacation',
			p1: '5.5" Wide x 8.5" Tall'
		}
	},
	{
		bgImage: bg_img1,
		info : {
			p: 'Biotrue ONEDay',
			previewImage: asset_popup_img,
			h4: 'Facebook Post Skiers',
			p1: '5.5" Wide x 8.5" Tall'
		}
	}
]
    return (
        <section className="content-right-main ecp_resource">
            <h1>Free Assets</h1> 
			<TabComponent tabs={tabs} setTab={handleTabChange} classes={{root: "tab-listing"}} activeTab={tab}/>
			<div className="main_tab_content">
				{
					cards.map((obj, index) => {
						return (
							<div className="content-box" key={index}>
							<div className="box-image" style={obj.bgImage}>
								<div className="button-hover">
									<div className="button-field">
										<button onClick={() => setmodal({isModalOpen: true, content: {image: obj.info.previewImage}})}>preview</button>
									</div>
									<div className="button-field">
										<button>download</button>
									</div>
								</div>
							</div>
							<div className="box-content-info">
								<p>{obj.info.p}</p>
								<h4>{obj.info.h4}</h4>
								<p>{obj.info.p1}</p>
							</div>
						</div>
						)
					})
				}
				</div>
				{
					modal && modal.isModalOpen && <ModalComponent show={modal.isModalOpen} handleClose={() => setmodal(null)}>
						<div className="popup-wrap">
                        <img src={modal.content.image} alt="place"/> 
                        <a href="#!" title="" className="gray-close-ic">
                            <img src={close_icon} alt="icon" onClick={() => setmodal(null)} />
                        </a>
                    </div>
					</ModalComponent>
				}
        </section>
    )
}

export default FreeAssets;
