import React, { useState } from 'react';
import close_ic from '../../../assets/images/close-ic.svg';
import otp_ic from '../../../assets/images/verify-otp-ic.svg';

const Verify = (props) => {
	const [otp, setOtp] = useState('');
	return (
		<div className='popup-box verify-otp-popup'>
			<div className='box-heading'>
				<img src={otp_ic} alt='icon' />
				<h2 className='h1'>Verify OTP</h2>
				<button className='close-btn' onClick={props.closeModal}>
					<img src={close_ic} alt='icon' />
				</button>
			</div>
			<div className='box-content'>
				<p>
					We've send you one time password to your registered mobile
					number.
				</p>
				<form action='#'>
					<div className='form-field'>
						<label>Please Enter OTP</label>
						<div className='multiple-field'>
							<input
								type='text'
								placeholder=''
								id='1'
								name='otp-field'
								maxLength={1}
								onChange={(e) => {
									if (e.target.value.toString().length >= 2) {
										return;
									}
									const value = Number(
										e.target.value.toString()
									);
									if (value >= 0) {
										const otpData = otp;
										setOtp(replaceAt(0, value, otpData));
										document.getElementById('2').focus();
									}
								}}
							/>
							<input
								type='text'
								placeholder=''
								id='2'
								name='otp-field'
								maxLength={1}
								onChange={(e) => {
									if (e.target.value.toString().length >= 2) {
										return;
									}
									const value = Number(
										e.target.value.toString()
									);
									if (value >= 0) {
										const otpData = otp;
										setOtp(replaceAt(1, value, otpData));
										document.getElementById('3').focus();
									}
								}}
							/>
							<input
								type='text'
								placeholder=''
								id='3'
								name='otp-field'
								maxLength={1}
								onChange={(e) => {
									if (e.target.value.toString().length >= 2) {
										return;
									}
									const value = Number(
										e.target.value.toString()
									);
									if ((value) => 0) {
										const otpData = otp;
										setOtp(replaceAt(2, value, otpData));
										document.getElementById('4').focus();
									}
								}}
							/>
							<input
								type='text'
								placeholder=''
								maxLength={1}
								id='4'
								name='otp-field'
								onChange={(e) => {
									if (e.target.value.toString().length >= 2) {
										return;
									}
									const value = Number(
										e.target.value.toString()
									);
									if (value >= 0) {
										const otpData = otp;
										setOtp(replaceAt(3, value, otpData));
										document.getElementById('1').focus();
									}
								}}
							/>
						</div>
					</div>
					<div className='form-field button-field'>
						<input
							type='button'
							value='verify OTP'
							name='verify-OTP'
							onClick={() => props.verifyOtp(otp)}
						/>
					</div>
					<a href='#!' title='Resend OTP'>
						Resend OTP
					</a>
				</form>
			</div>
		</div>
	);
};

/**
 *  replaceAt function is used to replace the otp string at specified number
 * @param {*} index
 * @param {*} chr
 * @param {*} str
 */
const replaceAt = (index, chr, str) => {
	return str.substr(0, index) + chr + str.substr(index + 1);
};

export default Verify;
