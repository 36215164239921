import React from 'react';
import WorkFlowComponent from '../component/workflow';
import user_pic1 from '../../../assets/images/edd.png';
import user_pic2 from '../../../assets/images/ryan.png';
import user_pic3 from '../../../assets/images/kinjal.png';
import user_pic4 from '../../../assets/images/sahil.png';
import Notification from '../../../shared/components/notification/notification';

class Workflow extends React.Component {

    state = {
        workflows: [{
            name: 'test',
            id: 1
        }, {
            name: 'Abc',
            id: 2
        }],
        users: [{
            id: 1,
            name: 'Edd',
            image: user_pic1
        },{
            id: 2,
            name: 'Ryan',
            image: user_pic2
        },{
            id: 3,
            name: 'Kinjal',
            image: user_pic3
        },{
            id: 4,
            name: 'Sahil',
            image: user_pic4
        }],
        userTypes: [{
            name: 'Baush & LombEd',
            id:  1
        }, {
            name: 'National Accounts',
            id:  2
        },{
            name: 'Supplier',
            id:  3
        },{
            name: 'Cobrand',
            id:  4
        }],
        Roles: [{
            id: 1,
            name: 'Super Admin'
        }, {
            id: 2,
            name: 'Admin'
        },{
            id: 3,
            name: 'Asset Approver'
        }],
        message: '',
        showNotification: false,
        isError: false
    }
    render () {
        return (
            <>
            <WorkFlowComponent {...this.state} handleSubmit={this.handleSubmit} />
            <Notification isError={this.state.isError} message={this.state.message} 
            showNotification={this.state.showNotification}
            clearNotification={this.handleClearNotification}
            closeNotification={this.handleClearNotification}
            />
            </>
        )
    }

    /**
     * handleSubmit method is used to submit data
     */
    handleSubmit = (values) => {
    
       this.setState({
        message: 'Data saved successfully!',
        showNotification: true,
        isError: false
       })

       setTimeout(() => {
           this.handleClearNotification();
       },2000)
    }

    handleClearNotification = () => {
        this.setState({
        message: '',
        showNotification: false,
        isError: false
        })
    }
}

export default Workflow;