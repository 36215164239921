import React from "react";
import Table from "../../../shared/components/table/table";
import TableHead from "../../../shared/components/table/tableHead";
import TableRow from "../../../shared/components/table/tableRow";
import TableBody from "../../../shared/components/table/tableBody";
import TableCell from "../../../shared/components/table/tableCell";
import double_left_arrow from '../../../assets/images/left-doble-arow.svg';
import left_arrow from '../../../assets/images/left-arrow.svg';
import double_right_arrow from '../../../assets/images/right-doble-arow.svg';
import right_arrow from '../../../assets/images/right-arrow.svg';
import "react-perfect-scrollbar/dist/css/styles.css";

import PerfectScrollbar from "react-perfect-scrollbar";

import deleteIcon from '../../../assets/images/delete.svg'
import editIcon from '../../../assets/images/edit.svg'
import filtericon from '../../../assets/images/ic_feather_filter.svg'

const UserList = (props) => {

	const headerList = ['action',"salutation",
	"first Name",
	"last Name",
	"user Name",
	"department",
	"position",
	"branch",	"email",
	"mobile",
	"active",
]
	const {currentPage,
        count,
        rowsPerPage,
        rowsPerPageOptions,
		list, to, from} = props;
		let userList = list;
		userList = list.slice(from, to)
		
		const page = Math.floor(count/rowsPerPage) + 1;

		/**
		 * handleChangeInCurrentPage method is used to change page number
		 * it will check that page number does not exist last page
		 * @param {*} value 
		 */
		const handleChangeInCurrentPage = (value) => {
			if(value <= page && Number(parseInt(value))) {
				props.handleChangePage(Number(value));
			}
		}
	return (
		<>
			<h1>View Users</h1>
			<div className="table-main-wrap users-class">
			<PerfectScrollbar  component='div'>
			
				<Table className="">
				<TableHead>
					<TableRow>
						{/* <TableCell>Action</TableCell> */}
						{headerList.map((obj, index) => {
							return <TableCell key={index}>{obj}</TableCell>;
						})}
						
					</TableRow>
					<TableRow>
						<TableCell>
						<span><img src={filtericon} alt="delete" /></span>
						</TableCell>
						<TableCell>
							<select onChange={(e) => props.applyFilter('salutation',e.target.value, 'select')}>
								<option value="">All</option>
								<option value="Mr">Mr</option>
								<option value="Mrs">Mrs</option>
							</select>
						</TableCell>
						<TableCell>
							<input type="text" name="" onChange={(e) => props.applyFilter('firstName',e.target.value , 'input')} />
						</TableCell>
						<TableCell>
							<input type="text" name="" onChange={(e) => props.applyFilter('lastName',e.target.value , 'input')} />
						</TableCell>
						<TableCell>
							<input type="text" name="" onChange={(e) => props.applyFilter('userName',e.target.value, 'input')} />
						</TableCell>
						<TableCell>
							<select onChange={(e) => props.applyFilter('department',e.target.value , 'select')}>
								<option value="">All</option>
								<option value="Development">Development</option>
								<option value="Marketing">Marketing</option>
							</select>
						</TableCell>
						<TableCell>
							<select onChange={(e) => props.applyFilter('position',e.target.value , 'select')}>
								<option value="">All</option>
								<option value="">All</option>
							</select>
						</TableCell>
						<TableCell>
							<select onChange={(e) => props.applyFilter('branch',e.target.value, 'select')}>
								<option value="">All</option>
								<option value="">All</option>
							</select>
						</TableCell>
						<TableCell>
							<input type="text" name="" onChange={(e) => props.applyFilter('email',e.target.value, 'input')} />
						</TableCell>
						<TableCell>
							<input type="text" name="" onChange={(e) => props.applyFilter('mobile',e.target.value, 'input')}  />
						</TableCell>
						<TableCell>
						<select onChange={(e) => props.applyFilter('active',e.target.value, 'select')}>
								<option value={""}>All</option>
								<option value={"true"}>Yes</option>
								<option value={"false"}>No</option>
							</select>
						</TableCell>

					</TableRow>
				</TableHead>
				<TableBody>
					{!!userList.length ? userList.map((obj, idnex) => {
						return (
							<TableRow key={idnex}>
								<TableCell>
									<span>
										<a href="#" title=""><img src={editIcon} alt="edit"/></a>
										<a href="#" title=""><img src={deleteIcon} alt="delete" /></a>
									 </span></TableCell>
								<TableCell>
									{obj.salutation}
									
									</TableCell>
								<TableCell>
									{obj.firstName}
									
								</TableCell>
								<TableCell>
									{obj.lastName}
									
									</TableCell>
								<TableCell>
									{obj.userName}
									
									</TableCell>
								<TableCell>
									{obj.department}
									
									</TableCell>
								<TableCell>
									{obj.position}
									
									</TableCell>
								<TableCell>
									{obj.branch}
									
									</TableCell>
								<TableCell>
									{obj.email}
									
									</TableCell>
								<TableCell>
									{obj.mobile}
									
									</TableCell>
								<TableCell className={`${obj.active  === "true"? 'green' : 'red'}`}><span>{obj.active === "true" ? 'Yes' : 'No'}</span></TableCell>
							</TableRow>
						);
					}) : <TableRow >
							<TableCell colSpan={headerList.length} className="not_found">
								No Data Found
							</TableCell>
						</TableRow>}
				</TableBody>
				
			</Table> 
				</PerfectScrollbar>
			</div>
			<div className="table-pagination">
				<div className="pagination-info">
					{console.log(to, count)}
					<p>Showing {from + 1} - {to > count ? count : to} of {count}</p>
				</div>
				<div className="pagination-control">
						<a href="#!" title="" className={`double-left-arrow ${currentPage === 1 || currentPage === 0 ? 'disabled' : ''}`} 
						onClick={(e) => {
							e.preventDefault();
							handleChangeInCurrentPage(1)}}
							><img src={double_left_arrow} alt="left" /></a>
						<a href="#!" title="" className={`simple-left-arrow ${currentPage === 1 || currentPage === 0 ? 'disabled' : ''}`}
						onClick={(e) => {
							e.preventDefault();
							handleChangeInCurrentPage(currentPage - 1)
						}}
						><img src={left_arrow} alt="single_left" /></a>
						<span>Page</span>
						<input type="text" name="textbox" value={currentPage === 0 ? currentPage + 1 :  currentPage} onChange={(e) => handleChangeInCurrentPage(e.target.value)} />
				<span>of {page}</span>
						<a href="#!" title="" className={`simple-right-arrow ${currentPage === page ? 'disabled' : ''}`}
						onClick={(e) => {
							e.preventDefault();
							handleChangeInCurrentPage(currentPage + 1)
						}}
						><img src={right_arrow} alt="right" /></a>
						<a href="#!" title="" className={`double-right-arrow ${currentPage === page ? 'disabled' : ''}`} 
						onClick={(e) =>{
							e.preventDefault();
							handleChangeInCurrentPage(page)}}><img src={double_right_arrow} alt="single_right" /></a>
						<select onChange={(e) => props.handleChangeRowsPerPage(parseInt(e.target.value))} defaultValue={rowsPerPage}>
							{
								rowsPerPageOptions.map((obj, index) => {
									return <option value={obj} key={index}>{obj}</option>
								})
							}
						</select>
				</div>
			</div>
		</>
	);
};

export default UserList;
