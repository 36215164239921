import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

class CustomScrollbars extends React.Component {

    render () {
        return (
            <Scrollbars
            style={{ width: 'auto', height: 300 }}
            
            // renderTrackHorizontal={({ style, ...props }) =>
            // <div {...props} style={{ ...style, backgroundColor: 'black' }}/>}
            renderThumbHorizontal={({ style, ...props }) =>
            <div {...props} style={{ ...style, backgroundColor: 'black', color: 'yellow', borderRadius: '5px' }}/>}
            renderThumbVertical={({ style, ...props }) =>
            <div {...props} style={{ ...style, backgroundColor: 'black', color: 'yellow' , borderRadius: '5px'}}/>}
            renderView={props => <div {...props} className="view"/>}>
              {this.props.children}
            </Scrollbars>
          );

    }
}

export default CustomScrollbars;