import React from 'react';

import Popover from '@material-ui/core/Popover';

import './popover.css'

const PopoverComponent = props => {
    const open = Boolean(props.anchorEl);
    const id = open ? props.id : undefined;
    const anchorEl  = props.anchorEl;
    return (
        <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={props.handleClose}
        disableScrollLock={true}
        className=''
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div className={`arrow-popup ${props.className}`}>
            {props.children}
        </div>
      </Popover>
    )
}

export default PopoverComponent