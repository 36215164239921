import React, { Component } from 'react'
import ResourceComponent from '../component/resource';
import ModalComponent from '../../../shared/components/modal/modal';
import popup_img from '../../../assets/images/ecp-popup-img.png';
import close_icon from '../../../assets/images/gray-close-ic.svg';


export default class ResourceContainer extends Component {

    state={
        isModalOpen: false
    }

    handleOpenModal = (value) => {
        this.setState({
            isModalOpen: true
        })
    }
    render() {
        return (
            <div>
                <ResourceComponent {...this.props} openModal={this.handleOpenModal} />

                <ModalComponent show={this.state.isModalOpen} handleClose={() => this.setState({isModalOpen: false})}>
                    <div className="popup-wrap">
                        <img src={popup_img} alt="place"/> 
                        <a href="#!" title="" className="gray-close-ic">
                            <img src={close_icon} alt="icon" onClick={() => this.setState({isModalOpen: false})} />
                        </a>
                    </div>
                </ModalComponent>
            </div>
        )
    }
}
