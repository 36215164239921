import React from 'react';
import Dashboard from '../component/home';
class HomeContainer extends React.Component  {
    render(){
        return (
            <>
                <Dashboard {...this.props} />
            </>
        )
    }
}

export default HomeContainer;