import React from 'react';

import {Formik, Field} from 'formik'

import error_ic from '../../../assets/images/error-icon.svg';
import check_ic from '../../../assets/images/check-icon.svg';

import './workflow.css'

const WorkFlowComponent = (props) => {
    const {workflows, users, userTypes, Roles} = props
    return (
        <>
            <section className="content-right-main">
                <h1>Workflow Configure</h1>

                <div className="configure-form-sec">
                    <Formik
                    enableReinitialize={true}
                        initialValues={{workflowName: '', users: [], userTypes: [], roles: [], allUsers: false, allUserTypes: false, allRoles: false}}
                        onSubmit={(value) => {
                            props.handleSubmit(value)
                        }}
                        validateOnChange
                        validateOnBlur
                    >
                        {({
                        values,
                        handleSubmit,
                        setFieldValue,
                        errors,
                        touched,
                    }) => {
                       
                        return (
                            <form onSubmit={handleSubmit}>
                            <div className="form_field_wrap">
                                <div className="form_field">
                                    <div className="field-left">
                                        <label>Workflow Name</label>
                                    </div>
                                    <div className="field-right">
                                        <select onChange={(e) => setFieldValue('workflowName', e.target.value)} value={values.workflowName}>
                                            <option value="">Workflow name</option>
                                            {
                                                workflows.map((obj, index) => {
                                                    return <option value={obj.name} key={index}>{obj.name}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                                </div>
                                <div className="users-info-wrap">
                                    <div className="users-info-col">
                                        <div className="info-head">
                                            <div className="custom-checkbox">
                                                <Field type='checkbox' name="allUsers" id="allUsers" 
                                                checked={values.allUsers} onChange={(e) => {
                                                    if(e.target.checked) {
                                                        const usersArray = []
                                                        setFieldValue('allUsers', true);
                                                        users.forEach((obj) => {
                                                            usersArray.push(obj)
                                                        });
                                                        setFieldValue('users', usersArray)
                                                    } else{
                                                        setFieldValue('users', [])
                                                        setFieldValue('allUsers', false)
                                                    }
                                                }} />
                                                <label htmlFor="allUsers">Users</label>
                                            </div>
                                           
                                        </div>
                                        <div className="info-body">
                                            {
                                                users.map((obj, index) => {
                                                    
                                                    return (
                                                        <div className="info-body-row" key={index}>
                                                
                                                        <div className="custom-checkbox">
                                                            <Field type='checkbox' name={`user-${obj.id}`}
                                                            checked={!!values.users.find((ob) => ob.id === obj.id)}
                                                            id={`user-${obj.id}`}
                                                             onChange={(e) => {
                                                                const existedUsers = values.users;
                                                                if(e.target.checked) {
                                                                   existedUsers.push(obj);
                                                                   
                                                                } else {
                                                                    const uncheckUserIndex = existedUsers.findIndex(ob => ob.id === obj.id);                                       
                                                                    existedUsers.splice(uncheckUserIndex, 1);
                                                                    setFieldValue('allUsers', false)
                                                                }
                                                                setFieldValue('users', existedUsers);
                                                                if(existedUsers.length === users.length) {
                                                                    setFieldValue('allUsers', true)
                                                                }
                                                            }}
                                                            />
                                                            <label htmlFor={`user-${obj.id}`}> <span className="user-pic"><img src={obj.image} alt="pic" /></span>{obj.name}</label>
                                                        </div>
                                                    </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                    
                                    <div className="users-info-col">
                                        <div className="info-head">
                                            <div className="custom-checkbox">
                                                <Field type='checkbox' name="allUserTypes" id="allUserTypes" 
                                                    checked={values.allUserTypes} onChange={(e) => {
                                                        if(e.target.checked) {
                                                            const usersArray = []
                                                            setFieldValue('allUserTypes', true);
                                                            userTypes.forEach((obj) => {
                                                                usersArray.push(obj)
                                                            });
                                                            setFieldValue('userTypes', usersArray)
                                                        } else{
                                                            setFieldValue('userTypes', [])
                                                            setFieldValue('allUserTypes', false)
                                                        }
                                                    }}
                                                />
                                                <label htmlFor="allUserTypes">User Type</label>
                                            </div>
                                           
                                        </div>
                                        <div className="info-body">
                                            {
                                                userTypes.map((obj, index) => {
                                                    return (
                                                        <div className="info-body-row" key={index}>
                                                            <div className="custom-checkbox">
                                                                <Field type='checkbox' name={`user-type-${obj.id}`}
                                                                checked={!! values.userTypes.find(ob => ob.id === obj.id)}
                                                                id={`user-type-${obj.id}`} onChange={(e) => {
                                                                const existedUserTypes = values.userTypes;
                                                                if(e.target.checked) {
                                                                   existedUserTypes.push(obj)
                                                                } else {
                                                                    const uncheckUserTypeIndex = existedUserTypes.findIndex(ob => ob.id === obj.id); 
                                                                    existedUserTypes.splice(uncheckUserTypeIndex, 1);
                                                                    setFieldValue('allUserTypes', false)
                                                                }
                                                                setFieldValue('userTypes', existedUserTypes);
                                                                if(existedUserTypes.length === userTypes.length) {
                                                                    setFieldValue('allUserTypes', true)
                                                                }
                                                            }}  />
                                                                <label htmlFor={`user-type-${obj.id}`}> {obj.name}</label>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                    <div className="users-info-col">
                                        <div className="info-head">
                                            <div className="custom-checkbox">
                                                <Field type='checkbox' name="allRoles" id="allRoles"
                                                    checked={values.allRoles} onChange={(e) => {
                                                        if(e.target.checked) {
                                                            const usersArray = []
                                                            setFieldValue('allRoles', true);
                                                            Roles.forEach((obj) => {
                                                                usersArray.push(obj)
                                                            });
                                                            setFieldValue('roles', usersArray)
                                                        } else{
                                                            setFieldValue('roles', [])
                                                            setFieldValue('allRoles', false)
                                                        }
                                                    }}
                                                />
                                                <label htmlFor="allRoles">Roles</label>
                                            </div>
                                           
                                        </div>
                                        <div className="info-body">
                                            {
                                                Roles.map((obj, index) => {
                                                    return (
                                                    <div className="info-body-row" key={index}>
                                                        <div className="custom-checkbox">
                                                            <Field type='checkbox' name={`roles-${obj.id}`} id={`roles-${obj.id}`}
                                                            checked={!! values.roles.find(ob => ob.id === obj.id)}
                                                            onChange={(e) => {
                                                                const existedRoles = values.roles;
                                                                if(e.target.checked) {
                                                                   existedRoles.push(obj)
                                                                  
                                                                } else {
                                                                    const existedRoleIndex = existedRoles.findIndex(ob => ob.id === obj.id)
                                                                    existedRoles.splice(existedRoleIndex, 1);
                                                                    setFieldValue('allRoles', false)
                                                                }
                                                            setFieldValue('roles', existedRoles);
                                                            if(existedRoles.length === Roles.length) {
                                                                setFieldValue('allRoles', true)
                                                            }
                                                            }}  />
                                                            <label htmlFor={`roles-${obj.id}`}>{obj.name}</label>
                                                        </div>
                                                    </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
        
                                <div className="form-submit">
                                    <div className="button-field">
                                        <input type="submit" name="save" value="save" />
                                    </div>
                                    <div className="button-field">
                                        <input type="submit" name="close" value="close" />
                                    </div>
                                </div>
                            </form>
                        

                        )
                    }
                }
                    </Formik>
                </div>
        </section>
        </>
    )
}





export default WorkFlowComponent;