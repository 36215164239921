export const userData = [
	{
		salutation: "Mr",
		firstName: "Ayush",
		lastName: "Agarwal",
		userName: "ayush",
		department: "Development",
		position: "Full Stack",
		branch: "Xyz",
		email: "xyz@gmail.com",
		mobile: "9999999999",
		active: "true",
	},
	{
		salutation: "Mrs",
		firstName: "Tejas",
		lastName: "Agarwal",
		userName: "ayush",
		department: "Development",
		position: "Full Stack",
		branch: "Xyz",
		email: "xyz@gmail.com",
		mobile: "9999999999",
		active: "true",
	},
	{
		salutation: "Mr",
		firstName: "Ayush",
		lastName: "Agarwal",
		userName: "ayush",
		department: "Development",
		position: "Full Stack",
		branch: "Xyz",
		email: "xyz@gmail.com",
		mobile: "9999999999",
		active: "false",
	},
	{
		salutation: "Mr",
		firstName: "Ayush",
		lastName: "Agarwal",
		userName: "ayush",
		department: "Marketing",
		position: "Full Stack",
		branch: "Xyz",
		email: "xyz@gmail.com",
		mobile: "9999999999",
		active: "true",
	},
	{
		salutation: "Mr",
		firstName: "Ayush",
		lastName: "Agarwal",
		userName: "ayush",
		department: "Marketing",
		position: "Full Stack",
		branch: "Xyz",
		email: "xyz@gmail.com",
		mobile: "9999999999",
		active: "true",
	},
	{
		salutation: "Mr",
		firstName: "Ayush",
		lastName: "Agarwal",
		userName: "ayush",
		department: "Marketing",
		position: "Full Stack",
		branch: "Xyz",
		email: "xyz@gmail.com",
		mobile: "9999999999",
		active: "false",
	},
	{
		salutation: "Mr",
		firstName: "Ayush",
		lastName: "Agarwal",
		userName: "ayush",
		department: "Development",
		position: "Full Stack",
		branch: "Xyz",
		email: "xyz@gmail.com",
		mobile: "9999999999",
		active: "true",
	},
	{
		salutation: "Mr",
		firstName: "Ayush",
		lastName: "Agarwal",
		userName: "ayush",
		department: "Development",
		position: "Full Stack",
		branch: "Xyz",
		email: "xyz@gmail.com",
		mobile: "9999999999",
		active: "true",
	},
	{
		salutation: "Mr",
		firstName: "Ayush",
		lastName: "Agarwal",
		userName: "ayush",
		department: "Development",
		position: "Full Stack",
		branch: "Xyz",
		email: "xyz@gmail.com",
		mobile: "9999999999",
		active: "false",
	},
	{
		salutation: "Mr",
		firstName: "Ayush",
		lastName: "Agarwal",
		userName: "ayush",
		department: "Development",
		position: "Full Stack",
		branch: "Xyz",
		email: "xyz@gmail.com",
		mobile: "9999999999",
		active: "true",
	},
	{
		salutation: "Mr",
		firstName: "Ayush",
		lastName: "Agarwal",
		userName: "ayush",
		department: "Development",
		position: "Full Stack",
		branch: "Xyz",
		email: "xyz@gmail.com",
		mobile: "9999999999",
		active: "true",
	},
	{
		salutation: "Mr",
		firstName: "Ayush",
		lastName: "Agarwal",
		userName: "ayush",
		department: "Development",
		position: "Full Stack",
		branch: "Xyz",
		email: "xyz@gmail.com",
		mobile: "9999999999",
		active: "false",
	},
	{
		salutation: "Mr",
		firstName: "Ayush",
		lastName: "Agarwal",
		userName: "ayush",
		department: "Development",
		position: "Full Stack",
		branch: "Xyz",
		email: "xyz@gmail.com",
		mobile: "9999999999",
		active: "true",
	},
	{
		salutation: "Mr",
		firstName: "Ayush",
		lastName: "Agarwal",
		userName: "ayush",
		department: "Development",
		position: "Full Stack",
		branch: "Xyz",
		email: "xyz@gmail.com",
		mobile: "9999999999",
		active: "true",
	},
	{
		salutation: "Mr",
		firstName: "Ayush",
		lastName: "Agarwal",
		userName: "ayush",
		department: "Development",
		position: "Full Stack",
		branch: "Xyz",
		email: "xyz@gmail.com",
		mobile: "9999999999",
		active: "false",
	},
	{
		salutation: "Mr",
		firstName: "Ayush",
		lastName: "Agarwal",
		userName: "ayush",
		department: "Development",
		position: "Full Stack",
		branch: "Xyz",
		email: "xyz@gmail.com",
		mobile: "9999999999",
		active: "true",
	},
	{
		salutation: "Mr",
		firstName: "Ayush",
		lastName: "Agarwal",
		userName: "ayush",
		department: "Development",
		position: "Full Stack",
		branch: "Xyz",
		email: "xyz@gmail.com",
		mobile: "9999999999",
		active: "true",
	},
	{
		salutation: "Mr",
		firstName: "Ayush",
		lastName: "Agarwal",
		userName: "ayush",
		department: "Development",
		position: "Full Stack",
		branch: "Xyz",
		email: "xyz@gmail.com",
		mobile: "9999999999",
		active: "false",
	},
	{
		salutation: "Mr",
		firstName: "Ayush",
		lastName: "Agarwal",
		userName: "ayush",
		department: "Development",
		position: "Full Stack",
		branch: "Xyz",
		email: "xyz@gmail.com",
		mobile: "9999999999",
		active: "true",
	},
	{
		salutation: "Mr",
		firstName: "Ayush",
		lastName: "Agarwal",
		userName: "ayush",
		department: "Development",
		position: "Full Stack",
		branch: "Xyz",
		email: "xyz@gmail.com",
		mobile: "9999999999",
		active: "true",
	},
	{
		salutation: "Mr",
		firstName: "Ayush",
		lastName: "Agarwal",
		userName: "ayush",
		department: "Development",
		position: "Full Stack",
		branch: "Xyz",
		email: "xyz@gmail.com",
		mobile: "9999999999",
		active: "false",
	},
	{
		salutation: "Mr",
		firstName: "Ayush",
		lastName: "Agarwal",
		userName: "ayush",
		department: "Development",
		position: "Full Stack",
		branch: "Xyz",
		email: "xyz@gmail.com",
		mobile: "9999999999",
		active: "true",
	},
	{
		salutation: "Mr",
		firstName: "Ayush",
		lastName: "Agarwal",
		userName: "ayush",
		department: "Development",
		position: "Full Stack",
		branch: "Xyz",
		email: "xyz@gmail.com",
		mobile: "9999999999",
		active: "true",
	},
	{
		salutation: "Mr",
		firstName: "Ayush",
		lastName: "Agarwal",
		userName: "ayush",
		department: "Development",
		position: "Full Stack",
		branch: "Xyz",
		email: "xyz@gmail.com",
		mobile: "9999999999",
		active: "false",
	},
	{
		salutation: "Mr",
		firstName: "Ayush",
		lastName: "Agarwal",
		userName: "ayush",
		department: "Development",
		position: "Full Stack",
		branch: "Xyz",
		email: "xyz@gmail.com",
		mobile: "9999999999",
		active: "true",
	},
	{
		salutation: "Mr",
		firstName: "Ayush",
		lastName: "Agarwal",
		userName: "ayush",
		department: "Development",
		position: "Full Stack",
		branch: "Xyz",
		email: "xyz@gmail.com",
		mobile: "9999999999",
		active: "true",
	},
	{
		salutation: "Mr",
		firstName: "Ayush",
		lastName: "Agarwal",
		userName: "ayush",
		department: "Development",
		position: "Full Stack",
		branch: "Xyz",
		email: "xyz@gmail.com",
		mobile: "9999999999",
		active: "false",
	},
	{
		salutation: "Mr",
		firstName: "Ayush",
		lastName: "Agarwal",
		userName: "ayush",
		department: "Development",
		position: "Full Stack",
		branch: "Xyz",
		email: "xyz@gmail.com",
		mobile: "9999999999",
		active: "true",
	},
	{
		salutation: "Mr",
		firstName: "Ayush",
		lastName: "Agarwal",
		userName: "ayush",
		department: "Development",
		position: "Full Stack",
		branch: "Xyz",
		email: "xyz@gmail.com",
		mobile: "9999999999",
		active: "true",
	},
	{
		salutation: "Mr",
		firstName: "Ayush",
		lastName: "Agarwal",
		userName: "ayush",
		department: "Development",
		position: "Full Stack",
		branch: "Xyz",
		email: "xyz@gmail.com",
		mobile: "9999999999",
		active: "false",
	},
	{
		salutation: "Mr",
		firstName: "Ayush",
		lastName: "Agarwal",
		userName: "ayush",
		department: "Development",
		position: "Full Stack",
		branch: "Xyz",
		email: "xyz@gmail.com",
		mobile: "9999999999",
		active: "true",
	},
	{
		salutation: "Mr",
		firstName: "Ayush",
		lastName: "Agarwal",
		userName: "ayush",
		department: "Development",
		position: "Full Stack",
		branch: "Xyz",
		email: "xyz@gmail.com",
		mobile: "9999999999",
		active: "true",
	},
	{
		salutation: "Mr",
		firstName: "Ayush",
		lastName: "Agarwal",
		userName: "ayush",
		department: "Development",
		position: "Full Stack",
		branch: "Xyz",
		email: "xyz@gmail.com",
		mobile: "9999999999",
		active: "false",
	},
	{
		salutation: "Mr",
		firstName: "Ayush",
		lastName: "Agarwal",
		userName: "ayush",
		department: "Development",
		position: "Full Stack",
		branch: "Xyz",
		email: "xyz@gmail.com",
		mobile: "9999999999",
		active: "true",
	},
	{
		salutation: "Mr",
		firstName: "Ayush",
		lastName: "Agarwal",
		userName: "ayush",
		department: "Development",
		position: "Full Stack",
		branch: "Xyz",
		email: "xyz@gmail.com",
		mobile: "9999999999",
		active: "true",
	},
	{
		salutation: "Mr",
		firstName: "Ayush",
		lastName: "Agarwal",
		userName: "ayush",
		department: "Development",
		position: "Full Stack",
		branch: "Xyz",
		email: "xyz@gmail.com",
		mobile: "9999999999",
		active: "false",
	},
	{
		salutation: "Mr",
		firstName: "Ayush",
		lastName: "Agarwal",
		userName: "ayush",
		department: "Development",
		position: "Full Stack",
		branch: "Xyz",
		email: "xyz@gmail.com",
		mobile: "9999999999",
		active: "true",
	},
	{
		salutation: "Mr",
		firstName: "Ayush",
		lastName: "Agarwal",
		userName: "ayush",
		department: "Development",
		position: "Full Stack",
		branch: "Xyz",
		email: "xyz@gmail.com",
		mobile: "9999999999",
		active: "true",
	},
	{
		salutation: "Mr",
		firstName: "Ayush",
		lastName: "Agarwal",
		userName: "ayush",
		department: "Development",
		position: "Full Stack",
		branch: "Xyz",
		email: "xyz@gmail.com",
		mobile: "9999999999",
		active: "false",
	},
	{
		salutation: "Mr",
		firstName: "Ayush",
		lastName: "Agarwal",
		userName: "ayush",
		department: "Development",
		position: "Full Stack",
		branch: "Xyz",
		email: "xyz@gmail.com",
		mobile: "9999999999",
		active: "true",
	},
	{
		salutation: "Mr",
		firstName: "Ayush",
		lastName: "Agarwal",
		userName: "ayush",
		department: "Development",
		position: "Full Stack",
		branch: "Xyz",
		email: "xyz@gmail.com",
		mobile: "9999999999",
		active: "true",
	},
	{
		salutation: "Mr",
		firstName: "Ayush",
		lastName: "Agarwal",
		userName: "ayush",
		department: "Development",
		position: "Full Stack",
		branch: "Xyz",
		email: "xyz@gmail.com",
		mobile: "9999999999",
		active: "false",
	},
	{
		salutation: "Mr",
		firstName: "Ayush",
		lastName: "Agarwal",
		userName: "ayush",
		department: "Development",
		position: "Full Stack",
		branch: "Xyz",
		email: "xyz@gmail.com",
		mobile: "9999999999",
		active: "true",
	},
	{
		salutation: "Mr",
		firstName: "Ayush",
		lastName: "Agarwal",
		userName: "ayush",
		department: "Development",
		position: "Full Stack",
		branch: "Xyz",
		email: "xyz@gmail.com",
		mobile: "9999999999",
		active: "true",
	},
	{
		salutation: "Mr",
		firstName: "Ayush",
		lastName: "Agarwal",
		userName: "ayush",
		department: "Development",
		position: "Full Stack",
		branch: "Xyz",
		email: "xyz@gmail.com",
		mobile: "9999999999",
		active: "false",
	},
	{
		salutation: "Mr",
		firstName: "Ayush",
		lastName: "Agarwal",
		userName: "ayush",
		department: "Development",
		position: "Full Stack",
		branch: "Xyz",
		email: "xyz@gmail.com",
		mobile: "9999999999",
		active: "true",
	},
	{
		salutation: "Mr",
		firstName: "Ayush",
		lastName: "Agarwal",
		userName: "ayush",
		department: "Development",
		position: "Full Stack",
		branch: "Xyz",
		email: "xyz@gmail.com",
		mobile: "9999999999",
		active: "true",
	},
	{
		salutation: "Mr",
		firstName: "Ayush",
		lastName: "Agarwal",
		userName: "ayush",
		department: "Development",
		position: "Full Stack",
		branch: "Xyz",
		email: "xyz@gmail.com",
		mobile: "9999999999",
		active: "false",
	},
	{
		salutation: "Mr",
		firstName: "Ayush",
		lastName: "Agarwal",
		userName: "ayush",
		department: "Development",
		position: "Full Stack",
		branch: "Xyz",
		email: "xyz@gmail.com",
		mobile: "9999999999",
		active: "true",
	},
	{
		salutation: "Mr",
		firstName: "Ayush",
		lastName: "Agarwal",
		userName: "ayush",
		department: "Development",
		position: "Full Stack",
		branch: "Xyz",
		email: "xyz@gmail.com",
		mobile: "9999999999",
		active: "true",
	},
	{
		salutation: "Mr",
		firstName: "Ayush",
		lastName: "Agarwal",
		userName: "ayush",
		department: "Development",
		position: "Full Stack",
		branch: "Xyz",
		email: "xyz@gmail.com",
		mobile: "9999999999",
		active: "false",
	},
	{
		salutation: "Mr",
		firstName: "Ayush",
		lastName: "Agarwal",
		userName: "ayush",
		department: "Development",
		position: "Full Stack",
		branch: "Xyz",
		email: "xyz@gmail.com",
		mobile: "9999999999",
		active: "true",
	},
	{
		salutation: "Mr",
		firstName: "Ayush",
		lastName: "Agarwal",
		userName: "ayush",
		department: "Development",
		position: "Full Stack",
		branch: "Xyz",
		email: "xyz@gmail.com",
		mobile: "9999999999",
		active: "true",
	},
	{
		salutation: "Mr",
		firstName: "Ayush",
		lastName: "Agarwal",
		userName: "ayush",
		department: "Development",
		position: "Full Stack",
		branch: "Xyz",
		email: "xyz@gmail.com",
		mobile: "9999999999",
		active: "false",
	},
	{
		salutation: "Mr",
		firstName: "Ayush",
		lastName: "Agarwal",
		userName: "ayush",
		department: "Development",
		position: "Full Stack",
		branch: "Xyz",
		email: "xyz@gmail.com",
		mobile: "9999999999",
		active: "true",
	},
	{
		salutation: "Mr",
		firstName: "Ayush",
		lastName: "Agarwal",
		userName: "ayush",
		department: "Development",
		position: "Full Stack",
		branch: "Xyz",
		email: "xyz@gmail.com",
		mobile: "9999999999",
		active: "true",
	},
	{
		salutation: "Mr",
		firstName: "Ayush",
		lastName: "Agarwal",
		userName: "ayush",
		department: "Development",
		position: "Full Stack",
		branch: "Xyz",
		email: "xyz@gmail.com",
		mobile: "9999999999",
		active: "false",
	},
	{
		salutation: "Mr",
		firstName: "Ayush",
		lastName: "Agarwal",
		userName: "ayush",
		department: "Development",
		position: "Full Stack",
		branch: "Xyz",
		email: "xyz@gmail.com",
		mobile: "9999999999",
		active: "true",
	},
	{
		salutation: "Mr",
		firstName: "Ayush",
		lastName: "Agarwal",
		userName: "ayush",
		department: "Development",
		position: "Full Stack",
		branch: "Xyz",
		email: "xyz@gmail.com",
		mobile: "9999999999",
		active: "true",
	},
	{
		salutation: "Mr",
		firstName: "Ayush",
		lastName: "Agarwal",
		userName: "ayush",
		department: "Development",
		position: "Full Stack",
		branch: "Xyz",
		email: "xyz@gmail.com",
		mobile: "9999999999",
		active: "false",
	},
	{
		salutation: "Mr",
		firstName: "Ayush",
		lastName: "Agarwal",
		userName: "ayush",
		department: "Development",
		position: "Full Stack",
		branch: "Xyz",
		email: "xyz@gmail.com",
		mobile: "9999999999",
		active: "true",
	},
	{
		salutation: "Mr",
		firstName: "Ayush",
		lastName: "Agarwal",
		userName: "ayush",
		department: "Development",
		position: "Full Stack",
		branch: "Xyz",
		email: "xyz@gmail.com",
		mobile: "9999999999",
		active: "true",
	},
	{
		salutation: "Mr",
		firstName: "Ayush",
		lastName: "Agarwal",
		userName: "ayush",
		department: "Development",
		position: "Full Stack",
		branch: "Xyz",
		email: "xyz@gmail.com",
		mobile: "9999999999",
		active: "false",
	},
	{
		salutation: "Mr",
		firstName: "Ayush",
		lastName: "Agarwal",
		userName: "ayush",
		department: "Development",
		position: "Full Stack",
		branch: "Xyz",
		email: "xyz@gmail.com",
		mobile: "9999999999",
		active: "true",
	},
	{
		salutation: "Mr",
		firstName: "Ayush",
		lastName: "Agarwal",
		userName: "ayush",
		department: "Development",
		position: "Full Stack",
		branch: "Xyz",
		email: "xyz@gmail.com",
		mobile: "9999999999",
		active: "true",
	},
	{
		salutation: "Mr",
		firstName: "Ayush",
		lastName: "Agarwal",
		userName: "ayush",
		department: "Development",
		position: "Full Stack",
		branch: "Xyz",
		email: "xyz@gmail.com",
		mobile: "9999999999",
		active: "false",
	},
	{
		salutation: "Mr",
		firstName: "Ayush",
		lastName: "Agarwal",
		userName: "ayush",
		department: "Development",
		position: "Full Stack",
		branch: "Xyz",
		email: "xyz@gmail.com",
		mobile: "9999999999",
		active: "true",
	},
	{
		salutation: "Mr",
		firstName: "Ayush",
		lastName: "Agarwal",
		userName: "ayush",
		department: "Development",
		position: "Full Stack",
		branch: "Xyz",
		email: "xyz@gmail.com",
		mobile: "9999999999",
		active: "true",
	},
	{
		salutation: "Mr",
		firstName: "Ayush",
		lastName: "Agarwal",
		userName: "ayush",
		department: "Development",
		position: "Full Stack",
		branch: "Xyz",
		email: "xyz@gmail.com",
		mobile: "9999999999",
		active: "false",
	},
	{
		salutation: "Mr",
		firstName: "Ayush",
		lastName: "Agarwal",
		userName: "ayush",
		department: "Development",
		position: "Full Stack",
		branch: "Xyz",
		email: "xyz@gmail.com",
		mobile: "9999999999",
		active: "true",
	},
	{
		salutation: "Mr",
		firstName: "Ayush",
		lastName: "Agarwal",
		userName: "ayush",
		department: "Development",
		position: "Full Stack",
		branch: "Xyz",
		email: "xyz@gmail.com",
		mobile: "9999999999",
		active: "true",
	},
	{
		salutation: "Mr",
		firstName: "Ayush",
		lastName: "Agarwal",
		userName: "ayush",
		department: "Development",
		position: "Full Stack",
		branch: "Xyz",
		email: "xyz@gmail.com",
		mobile: "9999999999",
		active: "false",
	},
	{
		salutation: "Mr",
		firstName: "Ayush",
		lastName: "Agarwal",
		userName: "ayush",
		department: "Development",
		position: "Full Stack",
		branch: "Xyz",
		email: "xyz@gmail.com",
		mobile: "9999999999",
		active: "true",
	},
	{
		salutation: "Mr",
		firstName: "Ayush",
		lastName: "Agarwal",
		userName: "ayush",
		department: "Development",
		position: "Full Stack",
		branch: "Xyz",
		email: "xyz@gmail.com",
		mobile: "9999999999",
		active: "true",
	},
	{
		salutation: "Mr",
		firstName: "Ayush",
		lastName: "Agarwal",
		userName: "ayush",
		department: "Development",
		position: "Full Stack",
		branch: "Xyz",
		email: "xyz@gmail.com",
		mobile: "9999999999",
		active: "false",
	},
	{
		salutation: "Mr",
		firstName: "Ayush",
		lastName: "Agarwal",
		userName: "ayush",
		department: "Development",
		position: "Full Stack",
		branch: "Xyz",
		email: "xyz@gmail.com",
		mobile: "9999999999",
		active: "true",
	},
	{
		salutation: "Mr",
		firstName: "Ayush",
		lastName: "Agarwal",
		userName: "ayush",
		department: "Development",
		position: "Full Stack",
		branch: "Xyz",
		email: "xyz@gmail.com",
		mobile: "9999999999",
		active: "true",
	},
	{
		salutation: "Mr",
		firstName: "Ayush",
		lastName: "Agarwal",
		userName: "ayush",
		department: "Development",
		position: "Full Stack",
		branch: "Xyz",
		email: "xyz@gmail.com",
		mobile: "9999999999",
		active: "false",
	},
	{
		salutation: "Mr",
		firstName: "Ayush",
		lastName: "Agarwal",
		userName: "ayush",
		department: "Development",
		position: "Full Stack",
		branch: "Xyz",
		email: "xyz@gmail.com",
		mobile: "9999999999",
		active: "true",
	},
	{
		salutation: "Mr",
		firstName: "Ayush",
		lastName: "Agarwal",
		userName: "ayush",
		department: "Development",
		position: "Full Stack",
		branch: "Xyz",
		email: "xyz@gmail.com",
		mobile: "9999999999",
		active: "true",
	},
	{
		salutation: "Mr",
		firstName: "Ayush",
		lastName: "Agarwal",
		userName: "ayush",
		department: "Development",
		position: "Full Stack",
		branch: "Xyz",
		email: "xyz@gmail.com",
		mobile: "9999999999",
		active: "false",
	},
	{
		salutation: "Mr",
		firstName: "Ayush",
		lastName: "Agarwal",
		userName: "ayush",
		department: "Development",
		position: "Full Stack",
		branch: "Xyz",
		email: "xyz@gmail.com",
		mobile: "9999999999",
		active: "true",
	},
	{
		salutation: "Mr",
		firstName: "Ayush",
		lastName: "Agarwal",
		userName: "ayush",
		department: "Development",
		position: "Full Stack",
		branch: "Xyz",
		email: "xyz@gmail.com",
		mobile: "9999999999",
		active: "true",
	},
	{
		salutation: "Mr",
		firstName: "Ayush",
		lastName: "Agarwal",
		userName: "ayush",
		department: "Development",
		position: "Full Stack",
		branch: "Xyz",
		email: "xyz@gmail.com",
		mobile: "9999999999",
		active: "false",
	},
	{
		salutation: "Mr",
		firstName: "Ayush",
		lastName: "Agarwal",
		userName: "ayush",
		department: "Development",
		position: "Full Stack",
		branch: "Xyz",
		email: "xyz@gmail.com",
		mobile: "9999999999",
		active: "true",
	},
	{
		salutation: "Mr",
		firstName: "Ayush",
		lastName: "Agarwal",
		userName: "ayush",
		department: "Development",
		position: "Full Stack",
		branch: "Xyz",
		email: "xyz@gmail.com",
		mobile: "9999999999",
		active: "true",
	},
	{
		salutation: "Mr",
		firstName: "Asdssddyush",
		lastName: "Agarwal",
		userName: "ayush",
		department: "Development",
		position: "Full Stack",
		branch: "Xyz",
		email: "xyz@gmail.com",
		mobile: "9999999999",
		active: "false",
	},
];
