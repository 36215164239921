import React from 'react';
import {Formik, Field} from 'formik'
import './userForm.css'

const UserForm = props => {
    const pathName = props.history.location.pathname.includes('add') ? 'Add' : 'Edit' 
    const initialValues = pathName === 'Add' ? {
        firstName: '',
        lastName: '',
        email: '',
        mobile: '',
        country: '',
        state: '',
        city: '',
        postcode: '',
        department: '',
        position: '',
        branch: '',
        active: ''
    } : {
        firstName: 'Ayush',
        lastName: 'Agarwal',
        email: 'ayush@gmial.com',
        mobile: '9723716816',
        country: 'India',
        state: 'Gujarat',
        department: 'Development',
        position: 'Full Stack',
        branch: 'Ahmedabad',
        city: 'Ahmedabad',
        postcode: '4445554',
        active: true
    }

    const countryData = [
        {
        name: 'India',
        id: 1,
        states: [{
            id: 1,
            name: 'Gujarat',
            cities: [{
                name: 'Ahmedabad',
                id: 1
            },{
                name: 'Surat',
                id: 2
            },{
                name: 'Gandhinagar',
                id: 3
            }]
        }, {
            id: 1,
            name: 'Maharashtra',
            cities: [{
                name: 'Mumbai',
                id: 1
            },{
                name: 'Nasik',
                id: 2
            },{
                name: 'Nagpur',
                id: 3
            }]
        }]
    }, {
        name: 'America',
        id: 1,
        states: [{
            id: 1,
            name: 'Punjab',
            cities: [{
                name: 'Amritsar',
                id: 1
            },{
                name: 'Haryana',
                id: 2
            },{
                name: 'Ludhiana',
                id: 3
            }]
        }, {
            id: 1,
            name: 'Kerela',
            cities: [{
                name: 'Chennai',
                id: 1
            },{
                name: 'Thiruvananthpuram',
                id: 2
            },{
                name: 'xyz',
                id: 3
            }]
        }]
    }]
    return (
        <>
         <section className="content-right-main">
                <h1>{pathName} Users</h1>
                <Formik
                    enableReinitialize={true}
                    initialValues={initialValues}
                    onSubmit={(values) => {console.log(values)}}
                >
                    {({
                        values,
                        handleSubmit,
                        setFieldValue,
                        errors,
                        touched,
                    }) => {
                    
                    const selectedCountry = values.country;
                    const selectedState = values.state;
                    const filteredStates = countryData.find(obj => obj.name === selectedCountry) ? countryData.find(obj => obj.name === values.country).states : []
                    const filteredCities = filteredStates.find(obj => obj.name === selectedState) ? filteredStates.find(obj => obj.name === selectedState).cities : []
                        return (
                    <div className="form-main">
                        <form onSubmit={handleSubmit}>
                            <div className="form_field">
                                    <div className="field-left">
                                        <label>First Name</label>
                                    </div>
                                    <div className="field-right">
                                        <Field type="text" placeholder="Enter First Name" name="firstName" value={values.firstName} />
                                    </div>
                            </div>
                            <div className="form_field">
                                <div className="field-left">
                                    <label>Last Name</label>
                                </div>
                                <div className="field-right">
                                    <Field type="text" placeholder="Enter Last Name" name="lastName" />
                                </div>
                            </div>
                            <div className="form_field">
                                <div className="field-left">
                                    <label>Email Address</label>
                                </div>
                                <div className="field-right">
                                    <Field type="email" placeholder="Enter Email Address" name="email" />
                                </div>
                            </div>
                            <div className="form_field">
                                <div className="field-left">
                                    <label>Mobile</label>
                                </div>
                                <div className="field-right">
                                    <Field type="text" placeholder="Enter Mobile" name="mobile" />
                                </div>
                            </div>
                            <div className="form_field">
                                <div className="field-left">
                                    <label>Department</label>
                                </div>
                                <div className="field-right">
                                    <select name='department' defaultValue={values.department} onChange={(e) =>  {setFieldValue('department',e.target.value);
                                    }}>
                                            <option value="">Select Department</option>
                                            <option value="">Select Department</option>
                                            <option value="">Select Department</option>
                                            
                                    </select>
                                </div>
                            </div>
                            <div className="form_field">
                                <div className="field-left">
                                    <label>Position</label>
                                </div>
                                <div className="field-right">
                                    <select name='position' defaultValue={values.position} onChange={(e) =>  {setFieldValue('position',e.target.value);
                                    }}>
                                            <option value="">Select Position</option>
                                            <option value="">Select Position</option>
                                            <option value="">Select Position</option>        
                                    </select>
                                </div>
                            </div>
                            <div className="form_field">
                                <div className="field-left">
                                    <label>Branch</label>
                                </div>
                                <div className='field-right'>
                                    <select name='branch' defaultValue={values.branch} onChange={(e) =>  {setFieldValue('branch',e.target.value);
                                        
                                    }}>
                                            <option value="">Select Branch</option>
                                            <option value="">Select Branch</option>
                                            <option value="">Select Branch</option>
                                    </select>
                                </div>
                            </div>
                            <div className="form_field">
                                <div className="field-left">
                                    <label>Country</label>
                                </div>
                                <div className="field-right">
                                    <select name='country' defaultValue={values.country} onChange={(e) =>  {setFieldValue('country',e.target.value);
                                    setFieldValue('state','')
                                }}>
                                        <option>Select Country</option>
                                        {
                                            countryData.map((obj, index) => {
                                            return <option value={obj.name} key={index}>{obj.name}</option>
                                            })
                                        }
                                        
                                    </select>
                                </div>
                            </div>

                            <div className="form_field">
                                <div className="field-left">
                                    <label>State</label>
                                </div>
                                <div className="field-right">
                                    <select name='state' defaultValue={values.state} onChange={(e) =>  {setFieldValue('state',e.target.value);
                                    setFieldValue('city','')
                                }}> 
                                        <option>Select State</option>
                                            {
                                              filteredStates.map((obj, index) => {
                                                return <option value={obj.name} key={index}>{obj.name}</option>
                                                })
                                            }
                                       
                                    </select>
                                </div>
                            </div>

                            <div className="form_field">
                                <div className="field-left">
                                    <label>City</label>
                                </div>
                                <div className="field-right">
                                    <select name='city' defaultValue={values.city} onChange={(e) =>  setFieldValue('city',e.target.value)}>
                                        <option>Select City</option>
                                        {
                                              filteredCities.map((obj, index) => {
                                                return <option value={obj.name} key={index}>{obj.name}</option>
                                                })
                                            }
                                        
                                    </select>
                                </div>
                            </div>

                            <div className="form_field">
                                <div className="field-left">
                                    <label>Postcode</label>
                                </div>
                                <div className="field-right">
                                    <Field type="text" placeholder="Enter postcode" name="postcode" />
                                </div>
                            </div>

                            <div className="form_field">
                                <div className="field-left">
                                    <label>Is Active ?</label>
                                </div>
                                <div className="field-right">
                                    <div className="custom-radio">
                                        <input type="radio" name="active" id="yes-text" defaultValue={values.active}  onClick={(e) => setFieldValue('active',true)}  />
                                        <label htmlFor="yes-text">Yes</label>
                                    </div>
                                    <div className="custom-radio">
                                        <input type="radio" name="active" id="no-text" defaultValue={values.active} onClick={(e) => setFieldValue('active',false)}/>
                                        <label htmlFor="no-text">No</label>
                                    </div>

                                </div>
                            </div>

                            <div className="form-submit">
                                <div className="button-field">
                                    <input type="submit" value="save" name="save" />
                                </div>
                                <div className="button-field disable-btn">
                                    <input type="submit" value="save & add new" name="save & add new" />
                                </div>
                                <div className="button-field disable-btn">
                                    <input type="button" value="close" name="close" onClick={() => props.history.goBack()}/>
                                </div>
                            </div>

                        </form>
                    </div>
                    ) }}

                </Formik>
        </section>
        </>
    )
}

export default UserForm;