import React from 'react';
import { useHistory, NavLink } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import authService from '../../../shared/services/auth-service';
import PopoverComponent from '../../../shared/components/popover/popover';
import logo from '../../../assets/images/logo.png';
import icon_cart from '../../../assets/images/Icon-cart.svg';
import ic_notification from '../../../assets/images/Icon-notifications.svg';
import ic_edit from '../../../assets/images/ic_edit.svg';
import ic_setting from '../../../assets/images/settings.svg';
import eyeclinic_logo from '../../../assets/images/eyeclinic-logo.svg';
import mo_avatar from '../../../assets/images/mob-avatar.svg';
import {ReactComponent as  Gray_ic_asset} from '../../../assets/images/gray_ic_assets.svg';
import {ReactComponent as  Gray_ic_clinic} from '../../../assets/images/gray_ic_clinic.svg';
import {ReactComponent as  Gray_ic_campaign} from '../../../assets/images/gray_ic_campaign.svg';
import {ReactComponent as  Ic_dashboard}  from '../../../assets/images/ic_dashboard.svg';
import {ReactComponent as  Ic_order}  from '../../../assets/images/ic_orders.svg';
import {ReactComponent as  Ic_admin}  from '../../../assets/images/ic_admin.svg';
import {ReactComponent as  Ic_wallet}  from '../../../assets/images/ic_wallet.svg';
import {ReactComponent as  Ic_coin}  from '../../../assets/images/coin.svg';
import ic_power_btn  from '../../../assets/images/power-button-ic.svg';
import HeaderNav from '../../../shared/components/header-nav/headerNav';

import './header.css';

const Header = (props) => {
	const [dropdown, setDropdown] = React.useState('');
	const [anchorEl, setAnchorEl] = React.useState(null);
	const history = useHistory();
	const handleClick = (event, tag) => {
		setDropdown(tag);
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	return (
		<header className='site-header'>
			<div className='header-left'>
				<div className="humburger-menu"  onClick={props.handleMenu}>
					<span></span>
					<span></span>
					<span></span>
				</div>
				<div className='site-logo'>
					<NavLink to="/home" title='Bausch n Lomb'>
						<img src={logo} alt='logo' />
					</NavLink>
				</div>
			</div>
			<div className='header-right'>
				
				<HeaderNav className="header-nav" >
					<li>
								<NavLink to='/dashboard'><Ic_coin />Co-Op<span className='icon-status'>1</span></NavLink>
							</li>
					</HeaderNav> 
						 {/* <div className="header-nav">
					<ul>

						<li>
							<NavLink to='/dashboard'><Ic_wallet />Co-Op<span className='icon-status'>1</span></NavLink>
						</li>
					</ul>
					
				</div>  */}
				
				
				
				<div className='right-icons'>
					<ul>
						<li>
							<a href='#!' title=''>
								<img src={icon_cart} alt='icon' />
								<span className='icon-status'>1</span>
							</a>
						</li>
						<li>
							<a href='#!' title='' onClick={(e) => handleClick(e, 'notification')}>
							
								<span className="icon-status">5</span>
								<img src={ic_notification} alt='icon' />
							</a>
						</li>
						
					</ul>
				</div>
				<div className='account-info'>
					<Button
						onClick={(e) => handleClick(e, 'profile')}
						aria-describedby={'simple-popover'}>
						<img src={props.innerWidth > 767 ? eyeclinic_logo : mo_avatar} alt='' />
						
					</Button>

					{/* popovercomponent is used to open popup component */}
					<PopoverComponent
						anchorEl={anchorEl}
						className={`${
							dropdown === 'profile' ? '' : 'notification'
						}`}
						handleClose={handleClose}
						id={'simple-popover'}>
						{dropdown === 'profile'
							? profileDropDown(props)
							: notificationDropDown(props)}
					</PopoverComponent>
				</div>
			
			</div>
		</header>
	);
};

/**
 * profileDropDown is used to render dropdown when user click on profile
 * @param {*} props
 */
const profileDropDown = props => {
    return (
        <ul className="profile-ul">
            <li>
                <a href='#!' title=''>
				
                    { <img src={ic_edit} alt='icon' /> }
                    Edit Profile
                </a>
            </li>
            <li>
                <a href='#!' title=''>
					
                    {<img src={ic_setting} alt='icon' /> }
                    Change Password
                </a>
            </li>
			<li>
                <a href='#!' title='' onClick={handleLogout}>
                    { <img src={ic_power_btn} alt='icon' />}
                    Logout
                </a>
            </li>
		</ul>
	);
};

/**
 * notification dropdown is used to render dropdown when user click on notification
 * @param {*} props
 */
const notificationDropDown = (props) => {
	return (
		<>
        <h6>Notifications (5)</h6>
		<ul>
            <li>
                <a href='#!' title=''>
					<div className="left-img">
						<Gray_ic_asset />
                    {/* <img src={gray_ic_asset} alt='icon' /> */}
					</div>
					<div className="right-content">
                   <p><strong>New Assets uploaded</strong></p>
				   <p><small>Assets</small></p>
				   <span className="noti-time">1:00 PM</span>
					</div>
                </a>
            </li>
			<li>
                <a href='#!' title=''>
					<div className="left-img">
						<Gray_ic_clinic />
                    {/* <img src={gray_ic_clinic} alt='icon' /> */}
					</div>
					<div className="right-content">
                   <p><strong>New Clinic registered</strong></p>
				   <p><small>Clinic</small></p>
				   <span className="noti-time">1:00 PM</span>
					</div>
                </a>
            </li>
			<li>
                <a href='#!' title=''>
					<div className="left-img">
						<Gray_ic_campaign />
                    {/* <img src={gray_ic_campaign} alt='icon' /> */}
					</div>
					<div className="right-content">
                   <p><strong>New Campaign Uploaded</strong></p>
				   <p><small>Campaign</small></p>
				   <span className="noti-time">1:00 PM</span>
					</div>
                </a>
            </li>
		</ul>
		</>
	);
};

/**
 *  handleLogout method is used to logout user and navigate to login
 * @param {*} props
 */
const handleLogout = (props) => {
	authService.removeAuthData();
};

export default Header;
