import React from 'react';
import welcome_img from '../../../assets/images/welcome-right-img.svg';

import './welcome-screen.css'

const WelcomeScreen = props => {
    return (
        <>
        <section className="content-right-main">
            <h1>Biotrue ONEday Digital Bundle</h1>
            <div className="two-col-sec digital-welcome">
                <div className="sec-left">
                    <p>Now that you have selected a product, let's walk through a few steps to complete your order. </p>
                    <p>This includes selecting the locations you would like to include in your campaign, confirming your location information, selecting the details of your campaign, and approving your campaign artwork.</p>
                        <p>Let's get started.</p>
                        <div className="button-field">
                            <button onClick={() => props.onStart()}>get started</button>
                        </div>
                </div>
                <div className="sec-right">
                    <img src={welcome_img} alt="image-1" />
                </div>
            </div> 
        </section>
       
        </>
    )
}


export default WelcomeScreen