import React from 'react';
import error_ic from '../../../assets/images/error-icon.svg';
import check_ic from '../../../assets/images/check-icon.svg';
import red_close_ic from '../../../assets/images/red-close-ic.svg';
import green_close_ic from '../../../assets/images/green-close-ic.svg';

class Notification extends React.Component {

    // componentDidUpdate =() => {
    //     setTimeout(() => {
    //         this.props.clearNotification()
    //     }, 5000)
    // }
   
    render() {
        return (
            <>
            {
                this.props.showNotification && 
                    <div className="form-messages">
                        {
                            this.props.isError ? 
                        <div className="messages-text error-msg-text">
                            <p><img src={error_ic} alt="icon" />
                            <strong>Error:</strong> {this.props.message}</p>
                            <a href="#" title="" className="alert-close-ic"><img src={red_close_ic} alt="close-ic" onClick={this.props.closeNotification} /></a>
                        </div> :  
                        <div className="messages-text success-msg-text"> 
                            <p><img src={check_ic} alt="icon" />
                        <strong>Success:</strong> {this.props.message}</p>
                        <a href="#" title="" className="alert-close-ic"><img src={green_close_ic} alt="close-ic" onClick={this.props.closeNotification} /></a>
                        </div>
                        }
                    </div>

            }
            </>
        )
    }
}

export default Notification