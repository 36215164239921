import React from 'react';
import digital_bundle from '../../../assets/images/bundle.svg';
import google_ads from '../../../assets/images/google-ad.svg';
import social_media from '../../../assets/images/social-media.svg';
import custom_print from '../../../assets/images/custom-print.svg';
import free_asset from '../../../assets/images/free-assets.svg';

import digital_bundle_hover from '../../../assets/images/Bundle_hover.svg';
import google_ads_hover from '../../../assets/images/google ad-hover.svg';
import social_media_hover from '../../../assets/images/social media_hover.svg';
import free_asset_hover from '../../../assets/images/free assets_hover.svg';
import custom_print_hover from '../../../assets/images/Custom Print_hover.svg';


import './product-selection.css'
import { useState } from 'react';
const ProductSelection = (props) => {
    const [hoverDiv, setHoverDiv] = useState(null)
    const cardDatas = [{
        title: 'Digital Bundle',
        heading: 'Digital Bundle',
        image1: digital_bundle,
        imageOnHover: digital_bundle_hover
    },{
        title: 'Google Ads',
        heading: 'Google Ads',
        image1: google_ads,
        imageOnHover: google_ads_hover
    },{
        title: 'Social Media',
        heading: 'Social Media',
        image1: social_media,
        imageOnHover: social_media_hover
    },{
        title: 'Custom Print',
        heading: 'Custom Print',
        image1: custom_print,
        imageOnHover: custom_print_hover
    },{
        title: 'Free Assets',
        heading: 'Free Assets',
        image1: free_asset,
        imageOnHover: free_asset_hover
    }]

    const CardComponent = props => {
        return (
            <>
            {
                cardDatas.map((obj, index) => {
                    return (
                        <div className="block-col" key={index} onMouseEnter={() => {
                            setHoverDiv(index)
                        }} 
                        onMouseLeave={() => {
                            setHoverDiv(null)
                        }}
                        onClick = {() => props.onSelectProduct(obj, index)}
                        
                        >
                            <a href="#!" title={obj.title} className="hover_link">
                            <img src={ hoverDiv === index ? obj.imageOnHover : obj.image1 } alt="icon" />
                                <p>Biotrue ONEday</p>
                                <h2>{obj.heading}</h2>
                                </a>
                        </div>
                    )
                })
            }
            </>
        )
    }


    
    return (
        <section className="content-right-main">
            <h1>Biotrue ONEday Family Campaign</h1>
            <section className="three-block-sec">
                <CardComponent  {...props}/>
            </section>
            
        </section>
    )
}




export default ProductSelection;