import React from 'react';
import logo from "../../../assets/images/logo.png";
import green_check_ic from "../../../assets/images/green-check-ic.svg";

import "../container/registration.css";

const ThankYouPage = props => {
    return (
        <>
        <Header />
		<section className="thank-you-page">
			<img src={green_check_ic} alt="ic" />
        <h1>Thank you for Registering!</h1>
		<p>Congratulations!! Your registration is completed for <strong>Name</strong>. Labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, <a href="#" title="click here">click here.</a></p>
		</section>
        </>
    )
}


const Header = (props) => {
	return (
		<>
			<header className='header-logo'>
				<a href='#!' title='Bausch n Lomb'>
					<img src={logo} alt='logo' />
				</a>
			</header>
		</>
	);
};
export default ThankYouPage;