import React, { Component } from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import './App.css';

import Layout from './hoc/layout/layout.component';
import AuthService from './shared/services/auth-service';

import Login from './features/login/container/login';
import Home from './features/home/container/home';
import Clinic from './features/clinic/container/clinic';
import UserContainer from './features/user/container/user';
import UserForm from './features/user/component/userForm'
import Workflow from './features/workflowConfiguration/container/workflow';
import ClinicRegistrationForm from './features/clinic/container/registrationForm';
import ThankYouPage from './features/clinic/component/thank-you';
import DigitalProduct from './features/digitalProduct/container/digitalProduct';
import WelcomeScreen from './features/digitalProduct/component/welcome-screen';
import CampaignDetail from './features/digitalProduct/component/campaignDetail';
import BudgetCampaignDuration from './features/digitalProduct/component/budgetDetail';
import LocationSelectionComponent from './features/digitalProduct/component/location-selection';
import LocationValidationComponent from './features/digitalProduct/component/location-validation';
import ResourceContainer from './features/ecp_resources/container/resource';
import FreeAssets from './features/ecp_resources/component/free-assets/free-assets';

class App extends Component {
	render() {
		const isLoggedIn = AuthService.checkLogin();
		if (isLoggedIn) {
			return (
				<Layout>
					<Switch>
						<Route path='/home' component={Home} />
						<Route path='/workflow/clinic' component={Clinic} />
						<Route path='/users' exact component={UserContainer} />
						<Route path='/users/add' exact component={UserForm} />
						<Route path='/users/edit/:id' exact component={UserForm} />
						<Route path='/clinic-registration' component={ClinicRegistrationForm} />
						<Route path='/workflow/configure' exact component={Workflow} />
						<Route path='/digital-product/selection' exact component={DigitalProduct} />
						<Route path='/digital-product/welcome' exact component={WelcomeScreen } />
						<Route path='/digital-product/campaignDetail' exact component={CampaignDetail} />
						<Route path='/digital-product/budget' exact component={BudgetCampaignDuration } />
						<Route path='/digital-product/location-selection' exact component={LocationSelectionComponent} />
						<Route path='/digital-product/location-validation' exact component={LocationValidationComponent } />


						<Route path="/resource/listing" exact component={ResourceContainer} />
						<Route path="/resource/assets" exact component={FreeAssets} />
						<Redirect to='/home' from='' />
					</Switch>
				</Layout>
			);
		}
		return (
			<>
				<Switch>
					<Route path='/login' component={Login} />
					<Route path='/clinic-registration' component={ClinicRegistrationForm} />
					<Route path="/thank-you" component={ThankYouPage}/>
					<Redirect to='/login' from='' />
				</Switch>
			</>
		);
	}
}

export default withRouter(App);
