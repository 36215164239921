export const addressData = [
	{
		addressName: "24$Music & Dvds, Bookstores",
		address1: "144 W 66th St",
		address2: "Upper West Side",
		postCode: "(212) 799-5000",
		country: "USA",
		state: "New York",
		city: "Beacon",
		email: "xyz@gmail.com",
		businessPhone: "9999999999",
		businessEmail: "true",
		website: "www.xwy.com",
		facebookUrl: "www.xwy.com",
		instagramUrl: "www.xwy.com",
	},
	{
		addressName: "24$Music & Dvds, Bookstores",
		address1: "144 W 66th St",
		address2: "Upper West Side",
		postCode: "(212) 799-5000",
		country: "USA",
		state: "New York",
		city: "Beacon",
		email: "xyz@gmail.com",
		businessPhone: "9999999999",
		businessEmail: "true",
		website: "www.xwy.com",
		facebookUrl: "www.xwy.com",
		instagramUrl: "www.xwy.com",
	},
	{
		addressName: "24$Music & Dvds, Bookstores",
		address1: "144 W 66th St",
		address2: "Upper West Side",
		postCode: "(212) 799-5000",
		country: "USA",
		state: "New York",
		city: "Beacon",
		email: "xyz@gmail.com",
		businessPhone: "9999999999",
		businessEmail: "true",
		website: "www.xwy.com",
		facebookUrl: "www.xwy.com",
		instagramUrl: "www.xwy.com",
	},
	{
		addressName: "24$Music & Dvds, Bookstores",
		address1: "144 W 66th St",
		address2: "Upper West Side",
		postCode: "(212) 799-5000",
		country: "USA",
		state: "New York",
		city: "Beacon",
		email: "xyz@gmail.com",
		businessPhone: "9999999999",
		businessEmail: "true",
		website: "www.xwy.com",
		facebookUrl: "www.xwy.com",
		instagramUrl: "www.xwy.com",
	},
	{
		addressName: "24$Music & Dvds, Bookstores",
		address1: "144 W 66th St",
		address2: "Upper West Side",
		postCode: "(212) 799-5000",
		country: "USA",
		state: "New York",
		city: "Beacon",
		email: "xyz@gmail.com",
		businessPhone: "9999999999",
		businessEmail: "true",
		website: "www.xwy.com",
		facebookUrl: "www.xwy.com",
		instagramUrl: "www.xwy.com",
	},
	{
		addressName: "24$Music & Dvds, Bookstores",
		address1: "144 W 66th St",
		address2: "Upper West Side",
		postCode: "(212) 799-5000",
		country: "USA",
		state: "New York",
		city: "Beacon",
		email: "xyz@gmail.com",
		businessPhone: "9999999999",
		businessEmail: "true",
		website: "www.xwy.com",
		facebookUrl: "www.xwy.com",
		instagramUrl: "www.xwy.com",
	},
	{
		addressName: "24$Music & Dvds, Bookstores",
		address1: "144 W 66th St",
		address2: "Upper West Side",
		postCode: "(212) 799-5000",
		country: "USA",
		state: "New York",
		city: "Beacon",
		email: "xyz@gmail.com",
		businessPhone: "9999999999",
		businessEmail: "true",
		website: "www.xwy.com",
		facebookUrl: "www.xwy.com",
		instagramUrl: "www.xwy.com",
	},
	{
		addressName: "24$Music & Dvds, Bookstores",
		address1: "144 W 66th St",
		address2: "Upper West Side",
		postCode: "(212) 799-5000",
		country: "USA",
		state: "New York",
		city: "Beacon",
		email: "xyz@gmail.com",
		businessPhone: "9999999999",
		businessEmail: "true",
		website: "www.xwy.com",
		facebookUrl: "www.xwy.com",
		instagramUrl: "www.xwy.com",
	},
	{
		addressName: "24$Music & Dvds, Bookstores",
		address1: "144 W 66th St",
		address2: "Upper West Side",
		postCode: "(212) 799-5000",
		country: "India",
		state: "New York",
		city: "Beacon",
		email: "xyz@gmail.com",
		businessPhone: "9999999999",
		businessEmail: "true",
		website: "www.xwy.com",
		facebookUrl: "www.xwy.com",
		instagramUrl: "www.xwy.com",
	},
	{
		addressName: "24$Music & Dvds, Bookstores",
		address1: "144 W 66th St",
		address2: "Upper West Side",
		postCode: "(212) 799-5000",
		country: "USA",
		state: "New York",
		city: "Beacon",
		email: "xyz@gmail.com",
		businessPhone: "9999999999",
		businessEmail: "true",
		website: "www.xwy.com",
		facebookUrl: "www.xwy.com",
		instagramUrl: "www.xwy.com",
	},
	{
		addressName: "24$Music & Dvds, Bookstores",
		address1: "144 W 66th St",
		address2: "Upper West Side",
		postCode: "(212) 799-5000",
		country: "USA",
		state: "New York",
		city: "Beacon",
		email: "xyz@gmail.com",
		businessPhone: "9999999999",
		businessEmail: "true",
		website: "www.xwy.com",
		facebookUrl: "www.xwy.com",
		instagramUrl: "www.xwy.com",
	},
	{
		addressName: "24$Music & Dvds, Bookstores",
		address1: "144 W 66th St",
		address2: "Upper West Side",
		postCode: "(212) 799-5000",
		country: "USA",
		state: "New York",
		city: "Beacon",
		email: "xyz@gmail.com",
		businessPhone: "9999999999",
		businessEmail: "true",
		website: "www.xwy.com",
		facebookUrl: "www.xwy.com",
		instagramUrl: "www.xwy.com",
	},
	{
		addressName: "24$Music & Dvds, Bookstores",
		address1: "144 W 66th St",
		address2: "Upper West Side",
		postCode: "(212) 799-5000",
		country: "USA",
		state: "New York",
		city: "Beacon",
		email: "xyz@gmail.com",
		businessPhone: "9999999999",
		businessEmail: "true",
		website: "www.xwy.com",
		facebookUrl: "www.xwy.com",
		instagramUrl: "www.xwy.com",
	},
	{
		addressName: "24$Music & Dvds, Bookstores",
		address1: "144 W 66th St",
		address2: "Upper West Side",
		postCode: "(212) 799-5000",
		country: "USA",
		state: "New York",
		city: "Beacon",
		email: "xyz@gmail.com",
		businessPhone: "9999999999",
		businessEmail: "true",
		website: "www.xwy.com",
		facebookUrl: "www.xwy.com",
		instagramUrl: "www.xwy.com",
	},
	{
		addressName: "24$Music & Dvds, Bookstores",
		address1: "144 W 66th St",
		address2: "Upper West Side",
		postCode: "(212) 799-5000",
		country: "USA",
		state: "New York",
		city: "Beacon",
		email: "xyz@gmail.com",
		businessPhone: "9999999999",
		businessEmail: "true",
		website: "www.xwy.com",
		facebookUrl: "www.xwy.com",
		instagramUrl: "www.xwy.com",
	},
	{
		addressName: "24$Music & Dvds, Bookstores",
		address1: "144 W 66th St",
		address2: "Upper West Side",
		postCode: "(212) 799-5000",
		country: "USA",
		state: "New York",
		city: "Beacon",
		email: "xyz@gmail.com",
		businessPhone: "9999999999",
		businessEmail: "true",
		website: "www.xwy.com",
		facebookUrl: "www.xwy.com",
		instagramUrl: "www.xwy.com",
	},
	{
		addressName: "24$Music & Dvds, Bookstores",
		address1: "144 W 66th St",
		address2: "Upper West Side",
		postCode: "(212) 799-5000",
		country: "USA",
		state: "New York",
		city: "Beacon",
		email: "xyz@gmail.com",
		businessPhone: "9999999999",
		businessEmail: "true",
		website: "www.xwy.com",
		facebookUrl: "www.xwy.com",
		instagramUrl: "www.xwy.com",
	},
	{
		addressName: "24$Music & Dvds, Bookstores",
		address1: "144 W 66th St",
		address2: "Upper West Side",
		postCode: "(212) 799-5000",
		country: "USA",
		state: "New York",
		city: "Beacon",
		email: "xyz@gmail.com",
		businessPhone: "9999999999",
		businessEmail: "true",
		website: "www.xwy.com",
		facebookUrl: "www.xwy.com",
		instagramUrl: "www.xwy.com",
	},
];
