import React from 'react';
import pro_img from '../../../assets/images/detail-pro-image.png';

import './campaignDetail.css'

const CampaignDetail = props => {
    const [approach, setApproach] = React.useState(null)
    const [message, setMessage] = React.useState(null)
    return (
        <><section className="content-right-main">
         <h1>Select your Campaign Details</h1>
            <form action="#!">
            <section className="two-block-sec">
                <div className="block-column">
                    <div className="column-head">
                        <h3>Select your creative approach</h3>
                    </div>
                    <div className="column-body">
                    <div className="column-body-row">
                        <div className="custom-radio">
                            <input type="radio" name="campaign_radio" id="campaign_radio1" onClick={() => setApproach("Biotrue Consumer Campaign")}/>
                            <label htmlFor="campaign_radio1">Biotrue Consumer Campaign</label>
                        </div>
                        <div className="custom-radio">
                            <input type="radio" name="campaign_radio" id="campaign_radio2" onClick={() => setApproach("Biotrue Product Campaign")} />
                            <label htmlFor="campaign_radio2">Biotrue Product Campaign</label>
                        </div>
                        </div>
                        <div className="column-body-img">
                        <img src={pro_img} alt="image" />
                        </div>
                    </div>
                </div>
                <div className="block-column">
                    <div className="column-head">
                        <h3>Select your Campaign Message</h3>
                    </div>
                    <div className="column-body">
                    <div className="column-body-row">
                        <div className="column-body-col">
                            <div className="custom-radio">
                                <input type="radio" name="Moisture" id="moisture1" onClick={() => setMessage("More Moisture")} />
                                <label htmlFor="moisture1">More Moisture</label>
                            </div>
                            <div className="custom-radio">
                                <input type="radio" name="Moisture" id="moisture2"  onClick={() => setMessage("16 Hours of Moisture")}/>
                                <label htmlFor="moisture2">16 Hours of Moisture</label>
                            </div>
                        </div>
                        <div className="column-body-col">
                            <div className="custom-radio">
                                <input type="radio" name="Moisture" id="moisture3"  onClick={() => setMessage("$200 in Rewards")}/>
                                <label htmlFor="moisture3">$200 in Rewards</label>
                            </div>
                            <div className="custom-radio">
                                <input type="radio" name="Moisture" id="moisture4" onClick={() => setMessage("$1 a Day")} />
                                <label htmlFor="moisture4">$1 a Day</label>
                            </div> 
                        </div>
                        </div>
                        <div className="column-body-img">
                        <img src={pro_img} alt="image" />
                        </div>
                    </div>
                </div>
                <div className={`button-field next-step-btn ${(!approach && !message) ? 'disable-btn': ''}`}>
                    <input type="button" name="submit" value="continue to next step"   onClick={() => props.submitCampaign({approach, message})}/>
                </div>
            </section>
            </form> 
            </section>
        </>
    )
}

export default CampaignDetail;