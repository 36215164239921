import React from "react";
import { NavLink } from "react-router-dom";

import "react-perfect-scrollbar/dist/css/styles.css";

import PerfectScrollbar from "react-perfect-scrollbar";

import "./sidebar.css";
import ExpansionPanelComponent from "../../../shared/components/expansionPanel/expansionPanel";

const Sidebar = (props) => {
	const [expanded, setExpanded] = React.useState("panel1");
	const sideBarNav = [
		{
			title: "Biotrue ONEday",
			path: "/workflow",
			subPath: [
				{
					title: "Digital Bundle",
					path: "/configure",
				},
				{
					title: "Google Ads",
					path: "/clinic",
				},
				{
					title: "Social Media",
					path: "/googleAds",
				},
			],
		},
		{
			title: "Biotrue ONEday Family Campaign",
			path: "/digital-product",
			subPath: [
				{
					title: "Digital Bundle",
					path: "/selection",
				},
				{
					title: "Google Ads",
					path: "/welcome",
				},
				{
					title: "Social Media",
					path: "/campaignDetail",
				},
				{
					title: "Custom Print",
					path: "/budget",
				},
				{
					title: "Free Assets",
					path: "",
				},
				{
					title: "Digital Bundle",
					path: "",
				},
				{
					title: "Google Ads",
					path: "/googleAds",
				}
			],
		},
		{
			title: "Bausch + Lomb ULTRA",
			path: "/resource",
			subPath: [
				{
					title: "Digital Bundle",
					path: "/listing",
				},
				{
					title: "Google Ads",
					path: "/assets",
				},
				{
					title: "Social Media",
					path: "/googleAds",
				},
			],
		},
		{
			title: "ECP Resources",
			path: "/oneDay",
			subPath: [
				{
					title: "Digital Bundle",
					path: "/digitalBundle",
				},
				{
					title: "Google Ads",
					path: "/googleAds",
				},
				{
					title: "Social Media",
					path: "/googleAds",
				},
			],
		},
	];
	return (
		<section className={`left-sidebar ${props.isSidebarOpen ? "active" : ""}`}>
			{/* <Scrollbars
				autoHeightMax='100%'
				autoHeightMin='100%'
				style={{ zIndex: 1001, width: "auto" }}
				renderView={(props) => <div {...props} className='view' />}
				renderThumbVertical={({ style, ...props }) => (
					<div
						{...props}
						style={{
							...style,
							backgroundColor: "#DDDDDD",
							color: "yellow",
							borderRadius: "2px",
						}}
					/>
				)}> */}
			<PerfectScrollbar
				component='div'
				options={{
					wheelSpeed: 0.5,
					wheelPropagation: true,
					minScrollbarLength: 20,
					swipeEasing: true,
					handlers: ["click-rail", "drag-thumb", "keyboard", "wheel", "touch"],
				}}>
				<nav>
					<ul>
						{sideBarNav.map((obj, index) => {
							return (
								<li key={index}>
									<ExpansionPanelComponent
										title={<span>{obj.title}</span> }
										className={"active"}
										icon={""}
										id={`panel${index + 1}`}
										expanded={expanded}
										controls={`panel${index + 1}`}
										setExpanded={setExpanded}>
										{obj.subPath.map((subObj, subIndex) => {
											return (
												<NavLink
													title={subObj.title}
													to={`${obj.path}${subObj.path}`}
													onClick={props.closeSidebar}
													activeClassName='active'
													key={subIndex}>
													{subObj.title}
													
												</NavLink>
											);
										})}
									</ExpansionPanelComponent>
								</li>
							);
						})}
					</ul>
				</nav>
			</PerfectScrollbar>
			{/* </Scrollbars> */}
		</section>
	);
};

export default Sidebar;
