import React, {useState} from 'react';
import forgot_user_ic from '../../../assets/images/forgot-username-ic.svg';
import close_ic from '../../../assets/images/close-ic.svg';
const ForgotPassword = props => {
    const [username, setUserName] = useState('')
    return (
        <div className="popup-box forgot-popup-box">
        <div className="box-heading">
            <img src={forgot_user_ic} alt="icon" />
            <h2 className="h1">Forgot Username</h2>
            <button className="close-btn"><img src={close_ic} alt="icon" onClick={props.closeModal} /></button>
        </div>
        <div className="box-content">
        <form action="#">
                <div className="form-field">
                    <label>Enter Your Email Address</label>
                    <input type="text" placeholder="name@domain.com" name="username" onChange={(e) => {
                        setUserName(e.target.value)
                    }} />
                </div>
               
                <div className="form-field button-field">
                    <input type="button" value="send my username" name="send-my-username" onClick={() => {
                        props.sendUserName(username)
                    }} />
                </div>
            </form>
            </div>
    </div>
    )
}

export default ForgotPassword;