import React from "react";
import Table from "../../../shared/components/table/table";
import TableHead from "../../../shared/components/table/tableHead";
import TableRow from "../../../shared/components/table/tableRow";
import TableBody from "../../../shared/components/table/tableBody";
import TableCell from "../../../shared/components/table/tableCell";

import "react-perfect-scrollbar/dist/css/styles.css";

import PerfectScrollbar from "react-perfect-scrollbar";

import deleteIcon from "../../../assets/images/delete.svg";
import editIcon from "../../../assets/images/edit.svg";
import filtericon from "../../../assets/images/ic_feather_filter.svg";
import CustomScrollbar from "../../../shared/components/custom-scrollbar/customScrollbar";

const AddressListComponent = (props) => {
    const list = props.list;
    const headings = ['Action','Address Name' , 'Address 1' , 'Address 2', 'Postcode' , 'Country', 'State', 'City', 'Email', 'Business Phone',
    'Business Email', 'Website', 'Facebook Url', 'Instagram Url']
	return (
		<>
			<div className='table-main-wrap'>
				<div className="table-link">
					<a href="#!" title="Add Address" onClick={(e) => {
						e.preventDefault();
						props.openAddressForm('address')
					}}>+ Add Address</a>
				</div>
				<PerfectScrollbar style={{ maxHeight: 600 }} component='div'>
					
						<Table className='' stickyHeader aria-label="sticky table">
							<TableHead>
								<TableRow>
									
									{headings.map((obj, index) => {
										return <TableCell key={index}>{obj}</TableCell>;
									})}
								</TableRow>
								<TableRow>
									<TableCell style={{top: 60}}>
										<span>
											<img src={filtericon} alt='delete' />
										</span>
									</TableCell>
									<TableCell style={{top: 60}}>
                                    <input
											type='text'
											name=''
											onChange={(e) =>
												props.applyFilter("addressName", e.target.value, "input")
											}
										/>
									</TableCell>
									<TableCell style={{top: 60}}>
										<input
											type='text'
											name=''
											onChange={(e) =>
												props.applyFilter("address1", e.target.value, "input")
											}
										/>
									</TableCell>
									<TableCell style={{top: 60}}>
										<input
											type='text'
											name=''
											onChange={(e) =>
												props.applyFilter("address2", e.target.value, "input")
											}
										/>
									</TableCell>
									<TableCell style={{top: 60}}>
										<input
											type='text'
											name=''
											onChange={(e) =>
												props.applyFilter("postCode", e.target.value, "input")
											}
										/>
									</TableCell>
									<TableCell style={{top: 60}}>
										<select
											onChange={(e) =>
												props.applyFilter(
													"country",
													e.target.value,
													"select"
												)
											}>
											<option value=''>All</option>
											<option value='Usa'>Usa</option>
											<option value='india'>India</option>
										</select>
									</TableCell>
									<TableCell style={{top: 60}}>
										<select
											onChange={(e) =>
												props.applyFilter("state", e.target.value, "select")
											}>
											<option value=''>All</option>
											<option value=''>All</option>
										</select>
									</TableCell>
									<TableCell style={{top: 60}}>
										<select
											onChange={(e) =>
												props.applyFilter("city", e.target.value, "select")
											}>
											<option value=''>All</option>
											<option value=''>All</option>
										</select>
									</TableCell>
									<TableCell style={{top: 60}}>
										<input
											type='text'
											name=''
											onChange={(e) =>
												props.applyFilter("email", e.target.value, "input")
											}
										/>
									</TableCell>
									<TableCell style={{top: 60}}>
										<input
											type='text'
											name=''
											onChange={(e) =>
												props.applyFilter("businessPhone", e.target.value, "input")
											}
										/>
									</TableCell>
									<TableCell style={{top: 60}}>
                                    <input
											type='text'
											name=''
											onChange={(e) =>
												props.applyFilter("businessEmail", e.target.value, "input")
											}
										/>
									</TableCell>
                                    <TableCell style={{top: 60}}>
                                    <input
											type='text'
											name=''
											onChange={(e) =>
												props.applyFilter("website", e.target.value, "input")
											}
										/>
									</TableCell>
                                    <TableCell style={{top: 60}}>
                                    <input
											type='text'
											name=''
											onChange={(e) =>
												props.applyFilter("facebookUrl", e.target.value, "input")
											}
										/>
									</TableCell>
                                    <TableCell style={{top: 60}}>
                                    <input
											type='text'
											name=''
											onChange={(e) =>
												props.applyFilter("instagramUrl", e.target.value, "input")
											}
										/>
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{!!list.length ? list.map((obj, idnex) => {
									return (
										<TableRow key={idnex}>
											<TableCell>
												<span>
													{" "}
													<img src={editIcon} alt='edit' />{" "}
													<img src={deleteIcon} alt='delete' />{" "}
												</span>
											</TableCell>
											<TableCell>{obj.addressName}</TableCell>
											<TableCell>{obj.address1}</TableCell>
											<TableCell>{obj.address2}</TableCell>
											<TableCell>{obj.postCode}</TableCell>
											<TableCell>{obj.country}</TableCell>
											<TableCell>{obj.state}</TableCell>
											<TableCell>{obj.city}</TableCell>
											<TableCell>{obj.email}</TableCell>
											<TableCell>{obj.businessPhone}</TableCell>
											<TableCell> {obj.businessEmail}</TableCell>
                                            <TableCell> {obj.website}</TableCell>
                                            <TableCell> {obj.facebookUrl}</TableCell>
                                            <TableCell> {obj.instagramUrl}</TableCell>
										</TableRow>
									);
								}) : <TableRow>
									<TableCell colSpan={headings.length} className="not_found">
										No Data Found
										</TableCell></TableRow>}
							</TableBody>
						</Table>
					
				</PerfectScrollbar>
			</div>
		</>
	);
};

export default AddressListComponent;
