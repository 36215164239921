import React from "react";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelComponent from "../../../shared/components/expansionPanel/expansionPanel";
import map_ic from "../../../assets/images/map-ic.svg";

import "./location.css";
import LocationValidationComponent from "./location-validation";

const LocationSelectionComponent = (props) => {
	const [expanded, setExpanded] = React.useState(null);

	const handleChange = (panel) => {
		setExpanded(panel);
	  };
	const [modalNumber, setModalNumber] = React.useState(null);

	const CheckBoxComponent = ({
		locationDetail,
		index,
		onSelectAddress,
		selectedLocations,
		onDeSelectAddress,
		updateLocationDetails,
	}) => {
		return (
			
				<div className='location-inner-content'>
					<div className='location-name'>
						<div className='custom-checkbox'>
							<input
								type='checkbox'
								name='location'
								id={`${locationDetail.id}`}
								checked={
									!!selectedLocations.length &&
									selectedLocations.find(
										(ob) => ob.id === locationDetail.id
									)
								}
								onChange={(e) => {
									if (e.target.checked) {
										onSelectAddress(locationDetail);
									} else {
										onDeSelectAddress(locationDetail);
									}
								}}
							/>
							<label htmlFor={`${locationDetail.id}`}>
								{locationDetail.location}
							</label>
						</div>
					</div>
					<div className='location-info'>
						<p>
							<img src={map_ic} alt='ic' />
							{locationDetail.area} {locationDetail.unit} radius
						</p>
					</div>
					<div
						className={`location-link ${
							locationDetail.needsToReview ? "red-link" : ""
							}`}>
						<a
							href='#!'
							title={` ${
								locationDetail.needsToReview
									? "Need Review"
									: "Location Details"
								}`}
							onClick={(e) => {
								e.preventDefault();
								setModalNumber(index)
								handleChange( expanded === `panel${index + 1}` ? null : `panel${index + 1}`)
							}}>
							{locationDetail.needsToReview
								? "Need Review"
								: "Location Details"}
						</a>
					</div>
				</div>
				
			
		);
	};

	return (
		<>
			<section className='content-right-main'>
				<h2>Select the Location you would like to place Advertising for</h2>
				<div className='location-screen-sec'>
					<div className="accordian_title">
						<div className="title-left">
							<h3>{props.category.name}</h3>
						</div>
						<div className="title-right">
							<p>$0.00</p>
						</div>
					</div>	
				<div className="loc-content-wrap">
					<div className="loc-content-main">
						{
							props.category.locations.map((obj, index) => {
								return (
									<ExpansionPanel expanded={expanded  === `panel${index + 1}`} key={index} >
										<ExpansionPanelSummary>
											
											<CheckBoxComponent
												locationDetail={obj}
												key={index}
												index={index}
												onSelectAddress={props.onSelectAddress}
												updateLocationDetails={props.updateLocationDetails}
												selectedLocations={props.selectAddress}
												onDeSelectAddress={props.onDeSelectAddress}
											/>
										</ExpansionPanelSummary>
										<ExpansionPanelDetails>
												{
													modalNumber === index && (
														<LocationValidationComponent
															locationDetail={obj.locationDetail}
															updateLocationDetails={(values) => {
																props.updateLocationDetails(values, obj.id)
																handleChange(null)
																setModalNumber(null);
															}}
														/>
													)
												}
										</ExpansionPanelDetails>
									</ExpansionPanel>		
								)
							})
						}				
					
					</div>						
				</div>
				</div>
				<div className={`button-field next-step-btn ${ (props.selectAddress.length === 0 || props.selectAddress.find(ob => ob.needsToReview === true)) ? 'disable-btn': ''}`}>
					<input type='button' name='button' value='continue to next step' onClick={props.nextForm} disabled={props.selectAddress.length === 0 || props.selectAddress.find(ob => ob.needsToReview === true)}/>
				</div>
			</section>
		</>
	);
};

export default LocationSelectionComponent;
