import React from "react";
import TextField from "@material-ui/core/TextField";
import { Formik, Field, ErrorMessage } from "formik";
 
import * as yup from 'yup';
import img_placeholder from "../../../assets/images/img-placeholder-img.png";

import "./location.css";
import MyMapComponent from "../../../shared/components/maps/google-maps";


const validation = yup.object().shape({
	businesInfo: yup.object().shape({
		email: yup.string().required("please enter business email address")
	})
})
const LocationValidationComponent = (props) => {
	const initialValues = props.locationDetail;
	const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
	const mapLocations = [{
		name: 'ACWORTH, GA',
		lat: '',
		lng: '',
		id: 1
	},{
		name: 'ATLANTA, GA',
		lat: '',
		lng: '',
		id: 2
	},{
		name: 'AUSTELL, GA',
		lat: '',
		lng: '',
		id: 3
	},{
		name: 'HIRAM, GA',
		lat: '',
		lng: '',
		id: 4
	},{
		name: 'KENNESAW, GA',
		lat: '',
		lng: '',
		id: 5
	},{
		name: 'LITHIA SPRINGS, GA',
		lat: '',
		lng: '',
		id: 6
	},{
		name: 'MABLETON, GA',
		lat: '',
		lng: '',
		id: 7
	},{
		name: 'MARIETTA, GA',
		lat: '',
		lng: '',
		id: 8
	}]

	return (
		<>
			<div className='business-info-form'>
				<Formik
					enableReinitialize={true}
					initialValues={initialValues}
					validationSchema={validation}
					onSubmit={(values) => props.updateLocationDetails(values)}>
					{({ handleSubmit, values, setFieldValue, isValid, errors,
											touched, }) => {
						// const locations = fetch(
						// 	"https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyD6NGlBCRNc6AoG9IqNYhgYiuyanlq4yvQ&components=postal_code:97403"
						// )
						// 	.then((res) => res.json())
						// 	.then((resp) => console.log(resp));
						return (
							<form onSubmit={handleSubmit}>
								<div className='business-info-wrap'>
									<div className='info-col-left'>
										<div className='white-box'>
											<div className='box-head'>
												<h4>business info</h4>
											</div>
											<div className='box-body'>
												<div className='field-full'>
													<Field
														type='text'
														placeholder='Business Name'
														name='businesInfo.name'
														value={values.businesInfo.name}
													/>
												</div>
												<div className='field-full'>
													<Field
														type='text'
														placeholder='Business Address'
														name='businesInfo.address1'
														value={values.businesInfo.address1}
													/>
												</div>
												<div className='field-full'>
													<Field
														type='text'
														name='businesInfo.suite'
														placeholder='Suite, Building (Optional)'
														value={values.businesInfo.suite}
													/>
												</div>
												<div className='field-double'>
													<div className='field-full'>
														<Field
															type='text'
															placeholder='Zip Code'
															name='businesInfo.postCode'
															value={values.businesInfo.postCode}
														/>
													</div>
													<div className='field-full'>
														<select
															onChange={(e) => {
																setFieldValue(
																	"businesInfo.city",
																	e.target.value
																);
															}}
															value={values.businesInfo.city}>
															<option value='Grand Rapids, MI'>
																Grand Rapids, MI
															</option>
															<option value='Grand Rapids, MI'>
																Grand Rapids, MI
															</option>
														</select>
													</div>
												</div>
												<div className='field-full'>
													<Field
														type='text'
														name='businesInfo.phone'
														placeholder='Business Phone Number'
														value={values.businesInfo.phone}
													/>
												</div>
												<div className='field-full'>
													<Field
														type='text'
														placeholder='Mobile Phone Number'
														name='businesInfo.businessPhone'
														value={values.businesInfo.businessPhone}
													/>
												</div>
												<div className={`field-full ${
														errors &&
														touched.businesInfo && touched.businesInfo.email &&
														errors.businesInfo &&
														errors.businesInfo.email !== ''
															? 'error'
															: ''
													}`}>
													<Field
														type='text'
														placeholder='Business email address'
														name='businesInfo.email'
														value={values.businesInfo.email}
													/>
													{errors &&
													touched.businesInfo &&
													 touched.businesInfo.email &&
														errors.businesInfo &&
														errors.businesInfo.email !== '' && (
																<span className='error-msg'>
																	<ErrorMessage
																		name='businesInfo.email'
																		render={(
																			err
																		) => {
																			return err;
																		}}
																	/>
																</span>
															)}
													
											
												</div>
												<div className='field-full'>
													<Field
														type='text'
														placeholder='Email marketing address'
														name='businesInfo.email2'
														value={values.businesInfo.email2}
													/>
												</div>
												<div className='field-full'>
													<Field
														type='text'
														placeholder='Business Website URL'
														name='businesInfo.website'
														value={values.businesInfo.website}
													/>
												</div>
											</div>
										</div>
										<div className='white-box'>
											<div className='box-head'>
												<h4>
													Social Media Links <i>(Not Required)</i>
												</h4>
											</div>
											<div className='box-body'>
												<div className='field-full'>
													<Field
														type='text'
														name='socialMedia.facebookUrl'
														placeholder='Facebook Link'
														value={values.socialMedia.facebookUrl}
													/>
												</div>
												<div className='field-full'>
													<Field
														type='text'
														name='socialMedia.instagramUrl'
														placeholder='Instagram Link'
														value={values.socialMedia.instagramUrl}
													/>
												</div>
											</div>
										</div>
									</div>
									<div className='info-col-right'>
										<div className='white-box'>
											<div className='box-head'>
												<h4>Business Logo</h4>
											</div>
											<div className='box-body'>
												<span className='preview-img'>
													<img
														src={values.logo ? window.URL.createObjectURL(
															new Blob([values.logo], { type: values.logo.type })) : img_placeholder}
														alt='preview-imag'
													/>
												</span>
												<div className='image-link-btn'>
													<div className='button-field file-upload-btn'>
														<input
															type='file'
															name='logo'
															id='upload-btn'
															onChange={(e) => {
																const file = e.target.files[0];
																if (file) {
																	setFieldValue("logo", file);
																}
															}}
														/>
														<label htmlFor='upload-btn'>Upload</label>
													</div>
													<div className='button-field'>
														<button
															onClick={() => {
																setFieldValue("logo", null);
															}}
															type='button'>
															delete
														</button>
													</div>
												</div>
											</div>
										</div>
										<div className='white-box'>
											<div className='box-head'>
												<h4>Hours Of Operation</h4>
											</div>
											<div className='box-body'>
												<div className='hours-table'>
													<div className='table-head'>
														<div className='head-col table-width-1'>
															<h5>Day</h5>
														</div>
														<div className='head-col table-width-2'>
															<h5>Open Time</h5>
														</div>
														<div className='head-col table-width-3'>
															<h5>Close Time</h5>
														</div>
														<div className='head-col table-width-4'>
															<h5>Holiday</h5>
														</div>
													</div>
													<div className='table-body'>
														{days.map((obj, index) => {
															return (
																<div className='table-body-row' key={index}>
																	<div className='table-data table-width-1'>
																		<h5>{obj}</h5>
																	</div>

																	<div className='table-data table-width-2'>
																		<TextField
																			id='time'
																			label=''
																			type='time'
																			value={values.hoursOfOperation[obj].open}
																			className={""}
																			InputLabelProps={{
																				shrink: true,
																			}}
																			onChange={(e) => {
																				setFieldValue(
																					`hoursOfOperation.${obj}.open`,
																					e.target.value
																				);
																			}}
																		/>
																	</div>
																	<div className='table-data table-width-3'>
																		<TextField
																			id='time'
																			label=''
																			type='time'
																			// defaultValue='06:00'
																			className={""}
																			InputLabelProps={{
																				shrink: true,
																			}}
																			value={values.hoursOfOperation[obj].close}
																			onChange={(e) => {
																				setFieldValue(
																					`hoursOfOperation.${obj}.close`,
																					e.target.value
																				);
																			}}
																		/>
																	</div>
																	<div className='table-data table-width-4'>
																		<div className='custom-checkbox'>
																			<Field
																				type='checkbox'
																				name=''
																				id={obj}
																				checked={
																					!!values.hoursOfOperation[obj].closed
																				}
																				onChange={(e) => {
																					if (e.target.checked) {
																						setFieldValue(
																							`hoursOfOperation.${obj}.closed`,
																							true
																						);
																					} else {
																						setFieldValue(
																							`hoursOfOperation.${obj}.closed`,
																							false
																						);
																					}
																				}}
																			/>
																			<label htmlFor={obj}></label>
																		</div>
																	</div>
																</div>
															);
														})}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className='info-col-full'>
									<div className='white-box'>
										<div className='box-head'> 
											<h4>Preferred Campaign Reach</h4>
										</div>
										<div className='box-body'>
											<div className='body-field'>
												<Field
													type='text'
													placeholder='Zip Code'
													name='campaignReach.zipCode'
													value={values.campaignReach.zipCode}
												/>
												<select
													value={values.campaignReach.radius}
													onChange={(e) => {
														setFieldValue(
															"campaignReach.radius",
															e.target.value
														);
													}}>
													<option value=''>Include Cities Within</option>
													<option value=''>Include Cities Within</option>
													<option value=''>Include Cities Within</option>
												</select>
											</div>
											<div className="map-sec">
												<div className="map-sec-left">
													{
														!!mapLocations.length && mapLocations.map((obj, index) => {
															return (
																<div className="custom-checkbox" key={index}>
																	<input type="checkbox" name="map-location" 
																	id={`map_loca_${index}`} checked={!!values.campaignReach.selectedLocations.find(ob => ob.id === obj.id)}
																	onChange={(e) => {
																		const selectedValues = values.campaignReach.selectedLocations;
																		if(e.target.checked) {
																			selectedValues.push(obj)
																		} else {
																			selectedValues.splice(selectedValues.findIndex(ob => ob.id === obj.id), 1);
																		}
																		setFieldValue('campaignReach.selectedLocations', selectedValues);
																	}}
																	 />
																	<label htmlFor={`map_loca_${index}`}>{obj.name}</label>
																</div>
															)
														})
													}
														{/* <div className="custom-checkbox">
															<input type="checkbox" name="map-location" id="map_loca_2" />
															<label htmlFor="map_loca_2">ATLANTA, GA</label>
														</div>
														<div className="custom-checkbox">
															<input type="checkbox" name="map-location" id="map_loca_3" />
															<label htmlFor="map_loca_3">AUSTELL, GA</label>
														</div>
														<div className="custom-checkbox">
															<input type="checkbox" name="map-location" id="map_loca_4" />
															<label htmlFor="map_loca_4">ACWORTH, GA</label>
														</div>
														<div className="custom-checkbox">
															<input type="checkbox" name="map-location" id="map_loca_5" />
															<label htmlFor="map_loca_5">HIRAM, GA</label>
														</div>
														<div className="custom-checkbox">
															<input type="checkbox" name="map-location" id="map_loca_6" />
															<label htmlFor="map_loca_6">KENNESAW, GA</label>
														</div>
														<div className="custom-checkbox">
															<input type="checkbox" name="map-location" id="map_loca_7" />
															<label htmlFor="map_loca_7">LITHIA SPRINGS, GA</label>
														</div>
														<div className="custom-checkbox">
															<input type="checkbox" name="map-location" id="map_loca_8" />
															<label htmlFor="map_loca_8">MABLETON, GA</label>
														</div>
														<div className="custom-checkbox">
															<input type="checkbox" name="map-location" id="map_loca_9" />
															<label htmlFor="map_loca_9">MARIETTA, GA</label>
														</div>
														<div className="custom-checkbox">
															<input type="checkbox" name="map-location" id="map_loca_10" />
															<label htmlFor="map_loca_10">POWDER SPRINGS, GA</label>
														</div>
														<div className="custom-checkbox">
															<input type="checkbox" name="map-location" id="map_loca_11" />
															<label htmlFor="map_loca_11">ROSWELL, GA</label>
														</div>
														<div className="custom-checkbox">
															<input type="checkbox" name="map-location" id="map_loca_12" />
															<label htmlFor="map_loca_12">WOODSTOCK, GA</label>
														</div> */}
												</div>
												<div className="map-sec-right">
													<MyMapComponent isMarkerShown currentZipCode={values.campaignReach.zipCode} locationDataSet={values.campaignReach.selectedLocations} />
												</div>	
											</div>
										</div>
									</div>
								</div>
								<div>
									<MyMapComponent isMarkerShown />
								</div>
								<div className={`button-field confirm-update ${!isValid ? 'disable-btn' : ''}`}>
									<input type='submit' name='submit' value='Confirm Updates' disabled={!isValid} />
								</div>
							</form>
						);
					}}
				</Formik>
			</div>
		</>
	);
};

export default LocationValidationComponent;
