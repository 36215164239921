import React from 'react';
import welcome_img from '../../../assets/images/welcome-right-img.svg';

import './budgetDetail.css'

const BudgetCampaignDuration = props => {
    const [budget, setBudget] = React.useState(props.budgetDetail.budget)
    const [duration, setDuration] = React.useState(props.budgetDetail.duration)
    return (
        <>
       <section className="content-right-main">
            <h1>Select your Budget and Campaign Duration</h1>
            <form action="#">
            <div className="two-col-sec detail-welcome">
                <div className="sec-left">
                    <p>This enhanced package of integrated marketing services promotes your practice locally to help attract patients. </p>                        
                    <h3>Package includes: </h3>
                    <ul>
                        <li>Pay-per-click Campaigns on Google</li>
                        <li>Display Ads on Facebook, WEb & Mobile</li>
                        <li>Keyword Research, Ad Group Creation, & Bid Mgmt</li>
                        <li>Custom Local Landing Page</li>
                        <li>Automated Customer Re-Engagement</li>
                    </ul>
                    <div className="full-field budget_detail_field">
                        <label>Select Your Budget</label>
                        <select value={budget} onChange={(e) => setBudget(e.target.value)}>
                            <option value="5000">$5,000.00</option>
                            <option value="10000">$10,000.00</option>
                        </select>
                    </div>
                    <div className="full-field budget_detail_field">
                        <label>Select your Campaign Duration</label>
                        <select value={duration} onChange={(e) => setDuration(e.target.value)}>
                            <option value="2">2 Months</option>
                            <option value="3">3 Months</option>
                        </select>
                        <p><small>* A minimum of 2 months required (3 months recommended) to optimize the performance of the campaign.</small></p>
                    </div>
                </div>
                <div className="sec-right">
                    <img src={welcome_img} alt="sd-1" />
                    <div className="blue-link">
                        <a href="#!" title="View Sample Artwork">View Sample Artwork</a>
                    </div>    
                </div>
                </div> 
                <div className={`button-field next-step-btn ${(!duration && !budget) ? 'disable-btn': ''}`}>
                    <input type="button" name="submit" value="continue to next step" onClick={() => props.submitBudget({duration, budget})} />
                </div>
          
            </form>
        </section>
        </>
    )
}

export default BudgetCampaignDuration;