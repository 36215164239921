import React from 'react';
import UserList from '../component/userlist';
import {data} from './data'
class UserContainer extends React.Component {

    state = {
        currentPage: 0,
        count: data.length,
        rowsPerPage: 5,
        rowsPerPageOptions: [5, 10, 25],
        list: data,
        from: 0, 
        to: 5
    }
    render() {
        return (
            <>
           <section className="content-right-main">
                <div className="button-field button-field-user">
                    <button onClick={() => {this.props.history.push('/users/add')}}>+ Add User</button>
                </div>
                <UserList {...this.state} handleChangeRowsPerPage={this.handleRowsPerPageChange}
                handleChangePage={this.handlePageChange}
                applyFilter={this.handleApplyFilter}
                />
            </section>
            </>
        )
    }

    /**
     * handleRowsPerPageChange method is used to update rows per page
     * it will reset page to 1
     */
    handleRowsPerPageChange = (value) => {
        let from = 0
        let to = value;
        this.setState({rowsPerPage: value, currentPage: 1, to: to, from : from})
    }

    /**
     * handlePageChange method is used to change page
     */
    handlePageChange = values => {
        let from = values === 1 ? 0 * this.state.rowsPerPage : (values - 1) * this.state.rowsPerPage
        let to = values === 1 ? this.state.rowsPerPage : (from + this.state.rowsPerPage) ;
        this.setState({currentPage: Number(values), to: to, from: from})
    }

    /**
     * handleApplyFilter method is used to apply filter on every columns
     */
    handleApplyFilter = (tag, values, type) => {
        console.log(values)
        if (values !== "") {
            let searchUsers = []
            /**
             * it will check if filter is apply from select than it will match exact keyword
             * else it will find nearest word
             */
            if(type === 'select') {
                 searchUsers = data.filter(
                
                  (us) => us[tag].toLowerCase() === values.toLowerCase()
                  );  
            } else {
                 searchUsers = data.filter(
                    (us) => us[tag].toLowerCase().indexOf(values.toLowerCase()) > -1
                  );
            }
            this.setState({ list: searchUsers , currentPage: 0,
                count: searchUsers.length,
                rowsPerPage: 5,
                rowsPerPageOptions: [5, 10, 25],
                from: 0, 
                to: 5 });
          } else {
            this.setState({ list: data , currentPage: 0,
                count: data.length,
                rowsPerPage: 5,
                rowsPerPageOptions: [5, 10, 25],
                from: 0, 
                to: 5});
          }
    }
}

export default UserContainer;