import React from 'react';
import welcome_img from '../../../assets/images/welcome-right-img.svg';

import './dashboard.css'

const Dashboard = props => {
    return (
        <>
        <section className="content-right-main">
            <h1>Welcome to Bausch + Lomb Reach</h1>
            <div className="two-col-sec">
                <div className="sec-left">
                    <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. </p>
                    <p>Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. </p>
                    <p>At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p>
                </div>
                <div className="sec-right">
                    <img src={welcome_img} alt="image"/>
                </div>
            </div>
           
          
            </section>
        </>
    )
}

export default Dashboard;