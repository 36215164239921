import React, { Component } from 'react'
import ProductSelection from '../component/product-selection';
import WelcomeScreen from '../component/welcome-screen';
import LocationSelectionComponent from '../component/location-selection';
import BudgetCampaignDuration from '../component/budgetDetail';
import CampaignDetail from '../component/campaignDetail';


const category = {
    id: 1, 
    name: 'Grand Rapids Opthalmology',
    locations: [
        {
            id: 1,
            name: '',
            location: '750 East Beltline, N.E. Grand Rapids, MI 49525',
            area: '5',
            unit: 'mi',
            needsToReview: true,
            locationDetail: {
                businesInfo: {
                    name: 'Grand Rapids Opthalmology',
                    address1: 'East Beltline',
                    suite: 'N.E.',
                    postCode: '49525',
                    phone: '700977-8001',
                    email: 'info@seel.com',
                    businessPhone: "",
                    email2: '',
                    website: 'siteclear.com',
                    city: 'Grand Rapids, MI'
                }, 
                socialMedia: {
                    facebookUrl: '',
                    instagramUrl: ''
                },
                logo: null,
                hoursOfOperation: {
                    Mon: {
                        open: '',
                        close: '',
                        closed: false
                    },
                    Tue: {
                        open: '',
                        close: '',
                        closed: false
                    },
                    Wed: {
                        open: '',
                        close: '',
                        closed: false
                    },
                    Thu: {
                        open: '',
                        close: '',
                        closed: false
                    },
                    Fri: {
                        open: '',
                        close: '',
                        closed: false
                    },
                    Sat: {
                        open: '',
                        close: '',
                        closed: false
                    },
                    Sun: {
                        open: '',
                        close: '',
                        closed: false
                    }
                },
                campaignReach: {
                    zipCode: '',
                    radius: '',
                    selectedLocations: []
                }
            }
        },
        {
            id: 2,
            name: '',
            location: '4475 Wilson, SW, Grandville, MI 49418',
            area: '5',
            unit: 'mi',
            needsToReview: false,
            locationDetail:{
                businesInfo: {
                    name: 'Grand Rapids Opthalmology',
                    address1: 'East Beltline',
                    suite: 'N.E.',
                    postCode: '49525',
                    phone: '700977-8001',
                    email: 'info@seel.com',
                    businessPhone: "",
                    email2: '',
                    website: 'siteclear.com',
                    city: 'Grand Rapids, MI'
                }, 
                socialMedia: {
                    facebookUrl: '',
                    instagramUrl: ''
                },
                logo: null,
                hoursOfOperation: {
                    Mon: {
                        open: '',
                        close: '',
                        closed: false
                    },
                    Tue: {
                        open: '',
                        close: '',
                        closed: false
                    },
                    Wed: {
                        open: '',
                        close: '',
                        closed: false
                    },
                    Thu: {
                        open: '',
                        close: '',
                        closed: false
                    },
                    Fri: {
                        open: '',
                        close: '',
                        closed: false
                    },
                    Sat: {
                        open: '',
                        close: '',
                        closed: false
                    },
                    Sun: {
                        open: '',
                        close: '',
                        closed: false
                    }
                },
                campaignReach: {
                    zipCode: '',
                    radius: '',
                    selectedLocations: []
                }
            }
        },
        {
            id: 3,
            name: '',
            location: '750 East Beltline, N.E. Grand Rapids, MI 49525',
            area: '5',
            unit: 'mi',
            needsToReview: false,
            locationDetail:{
                businesInfo: {
                    name: 'Grand Rapids Opthalmology',
                    address1: 'East Beltline',
                    suite: 'N.E.',
                    postCode: '49525',
                    phone: '700977-8001',
                    email: 'info@seel.com',
                    businessPhone: "",
                    email2: '',
                    website: 'siteclear.com',
                    city: 'Grand Rapids, MI'
                }, 
                socialMedia: {
                    facebookUrl: '',
                    instagramUrl: ''
                },
                logo: null,
                hoursOfOperation: {
                    Mon: {
                        open: '',
                        close: '',
                        closed: false
                    },
                    Tue: {
                        open: '',
                        close: '',
                        closed: false
                    },
                    Wed: {
                        open: '',
                        close: '',
                        closed: false
                    },
                    Thu: {
                        open: '',
                        close: '',
                        closed: false
                    },
                    Fri: {
                        open: '',
                        close: '',
                        closed: false
                    },
                    Sat: {
                        open: '',
                        close: '',
                        closed: false
                    },
                    Sun: {
                        open: '',
                        close: '',
                        closed: false
                    }
                },
                campaignReach: {
                    zipCode: '',
                    radius: '', selectedLocations: []
                }
            }
        },{
            id: 4,
            name: '',
            location: '750 East Beltline, N.E. Grand Rapids, MI 49525',
            area: '5',
            unit: 'mi',
            needsToReview: false,
            locationDetail:{
                businesInfo: {
                    name: 'Grand Rapids Opthalmology',
                    address1: 'East Beltline',
                    suite: 'N.E.',
                    postCode: '49525',
                    phone: '700977-8001',
                    email: 'info@seel.com',
                    businessPhone: "",
                    email2: '',
                    website: 'siteclear.com',
                    city: 'Grand Rapids, MI'
                }, 
                socialMedia: {
                    facebookUrl: '',
                    instagramUrl: ''
                },
                logo: null,
                hoursOfOperation: {
                    Mon: {
                        open: '',
                        close: '',
                        closed: false
                    },
                    Tue: {
                        open: '',
                        close: '',
                        closed: false
                    },
                    Wed: {
                        open: '',
                        close: '',
                        closed: false
                    },
                    Thu: {
                        open: '',
                        close: '',
                        closed: false
                    },
                    Fri: {
                        open: '',
                        close: '',
                        closed: false
                    },
                    Sat: {
                        open: '',
                        close: '',
                        closed: false
                    },
                    Sun: {
                        open: '',
                        close: '',
                        closed: false
                    }
                },
                campaignReach: {
                    zipCode: '',
                    radius: '',
                    selectedLocations: []
                }
            }
        }
    ]

}


export default class DigitalProduct extends Component {

    state = {
        categories: category,
        productNumber: null,
        isSelected: false,
        isStarted: false,
        selectedAdd: [],
        currentForm: 1,
        budgetDetail: {
            budget: undefined,
            duration: undefined
        },
        campaignDetail: {
            approach: undefined,
            message: undefined
        }
    }

    /**
     * handleSelectProduct method is used to select product screen
     */
    handleSelectProduct= (obj, index) =>  {
        this.setState({
            productNumber: index + 1,
            currentForm: 2
        })
    }

    /**
     * handleStartSelection method is used to start product form
     */
    handleStartSelection = () => {
        this.setState({
            isStarted: true,
            currentForm: 3
        })
    }

    /**
     * handleSelectAddress method is used to push selected address key and index in selectedAddress
     */
    handleSelectAddress = (values) => {
        const selectedAddress = this.state.selectedAdd;
        selectedAddress.push(values);
        this.setState({
            selectedAdd: selectedAddress
        })
    }

    /**
     * handleDeSelectAddress method is used to remove selected key and index from state in selected address
     */
    handleDeSelectAddress = (values) => {
        const selectedAddress = this.state.selectedAdd;
        const index = selectedAddress.findIndex(ob => ob.id === values.id);
        selectedAddress.splice(index, 1);
        this.setState({
            selectedAdd: selectedAddress
        })
    }

    handleUpdateLocationDetail = (obj, key) => {
        const locations = this.state.categories.locations;
        const selectedLocation = locations.find(obj =>  obj.id === key);
        const selectedLocationIndex = locations.findIndex(obj =>  obj.id === key);
        selectedLocation.locationDetail = obj;
        selectedLocation.name = obj.businesInfo.name;
        selectedLocation.location = `${obj.businesInfo.address1} ${obj.businesInfo.suite} ${obj.businesInfo.city} ${obj.businesInfo.postCode}`
        selectedLocation.needsToReview = false;
        locations.splice(selectedLocationIndex, 1, selectedLocation);
        this.setState({
            categories: {...this.state.categories, locations: locations}
        })
    }

    handleBudgetDetailSelection= ({duration, budget}) => {
        this.setState({
            budgetDetail: {
                duration,
                budget
            },
            currentForm: 5
        })
    }

    handleSubmitCampaign = ({approach, message}) => {
        this.setState({
            campaignDetail: {
                approach,
                message
            }
        })
    }
    render() {
        return (
            <div>
                {
                    this.state.currentForm === 1 && <ProductSelection onSelectProduct={this.handleSelectProduct} />
                }
                {
                    this.state.currentForm === 2 && <WelcomeScreen onStart={this.handleStartSelection} />
                }
                {
                    this.state.currentForm === 3 && <LocationSelectionComponent 
                     category={this.state.categories} onSelectAddress={this.handleSelectAddress} selectAddress={this.state.selectedAdd}
                     onDeSelectAddress={this.handleDeSelectAddress}
                     updateLocationDetails={this.handleUpdateLocationDetail}
                     nextForm={() =>this.setState({currentForm: 4}, () => {console.log(this.state.currentForm)})}
                     />  
                }
                {
                    this.state.currentForm === 4 && <BudgetCampaignDuration budgetDetail={this.state.budgetDetail}
                    submitBudget={this.handleBudgetDetailSelection}
                    />
                }
                {
                    this.state.currentForm === 5 && <CampaignDetail submitCampaign={this.handleSubmitCampaign}/>
                }
            </div>
        )
    }
}
