import React from 'react';
import placeholder_img from '../../../assets/images/placeholder-img.svg';

import "./resource.css";
import TabComponent from '../../../shared/components/tab/tab';
var bg_img = {
	backgroundImage: `url(${placeholder_img})`,
};

const ResourceComponent  = (props) => {
	const tabs = ['EHR Tools' , 'Fitting & Parameter Guides', 'Testimonials', 'websites for ECPs', 'other Resources'];
	const [tab, setTab] = React.useState(0);

	const handleTabChange = (value) => {
		setTab(value)
	}

	const cards = [{
		bgImage: bg_img,
		info : {
			p: 'Find new growth opportunities',
			h4: 'Crystal Practice Management EHR System',
			p1: '5.5" Wide x 8.5" Tall'
		}
	}, {
		bgImage: bg_img,
		info : {
			p: 'Find new growth opportunities',
			h4: 'Compulink Advantage SMART Practice System',
			p1: '5.5" Wide x 8.5" Tall'
		}
	}, {
		bgImage: bg_img,
		info : {
			p: 'Find new growth opportunities',
			h4: 'Revolution EHR System',
			p1: '5.5" Wide x 8.5" Tall'
		}
	},
	{
		bgImage: bg_img,
		info : {
			p: 'Find new growth opportunities',
			h4: 'OfficeMate - Eyefinity EHR System',
			p1: '5.5" Wide x 8.5" Tall'
		}
	}
]
    return (
        <>
        <section className="content-right-main ecp_resource">
        <h1>Bausch + Lomb Resources</h1>

		<TabComponent tabs={tabs} setTab={handleTabChange} classes={{root: "tab-listing"}} activeTab={tab}/>
				<div className="main_tab_content">
					{
						cards.map((obj, index) => {
							return (
								<div className="content-box" key={index}>
									<div className="box-image" style={obj.bgImage}>
										<div className="button-hover">
											<div className="button-field">
												<button onClick={(e) => {
													props.openModal(1);
												}}>preview</button>
											</div>
											<div className="button-field">
												<button>download</button>
											</div>
										</div>
									</div>
									<div className="box-content-info">
										<p>{obj.info.p}</p>
										<h4>{obj.info.h4}</h4>
										<p>{obj.info.p1}</p>
									</div>
					</div>
							)
						})
					}
				</div>
                </section>
        </>
    )
}


export default ResourceComponent;