import React from 'react';

import ClinicFormComponent from '../component/clinicForm'
import AddressComponent from '../component/addressList';
import UserListComponent from '../component/userList';
import {userData} from '../component/userData'
import {addressData} from '../component/addressData'
import AddressListComponent from '../component/addressList';
import ModalComponent from '../../../shared/components/modal/modal';
import AddressForm from '../component/addressForm';
class Clinic extends React.Component {
    state = {
        activeTab: 0,
        tabs: ['Information' , 'Address', 'Users'],
        usersList: userData,
        addressList: addressData,
        isModalOpen: false,
        tag: ''
    }
    render() {
        return (
            <section className="content-right-main">
                <h1>Clinics</h1>
            <div className="tab-listing">
                <ul>
                    {
                        this.state.tabs.map((obj, index) => {
                            return <li key={index}>
                                        <a href="#!" title={obj} className={`${this.state.activeTab === index ? 'active': ''}`}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                this.handleTabChange(index)}}
                                        >{obj}</a>
                                   </li> 
                        })
                    }
                </ul>
                <span className="tab-arrow"></span>
            </div>
            {
                this.state.activeTab === 0 ? <ClinicFormComponent /> : this.state.activeTab === 1 ?
                <AddressListComponent list={this.state.addressList}
                    applyFilter={this.handleAddressFilter}
                    openAddressForm={this.handleModalOpen}
                /> : <UserListComponent list={this.state.usersList}
                 applyFilter={this.handleApplyFilter}
                 /> 
            }   


            {
                this.state.isModalOpen && this.state.tag === 'address' &&
                <ModalComponent show={this.state.isModalOpen} handleClose={() => this.setState({tag: '', isModalOpen: false})}>
                    <AddressForm  closeModal={() => this.setState({tag: '', isModalOpen: false})}/>
                </ModalComponent>
            }
            </section>
            
        )
    }

    /**
     * handleTabChange function is used to change tab
     */
    handleTabChange = (tab) => {
        this.setState({
            activeTab: tab
        })
    }

    /**
     * handleApplyFilter method is used to apply filter on every columns
     */
    handleApplyFilter = (tag, values, type) => {
        if (values !== "") {
            let searchUsers = []
            /**
             * it will check if filter is apply from select than it will match exact keyword
             * else it will find nearest word
             */
            if(type === 'select') {
                 searchUsers = userData.filter(
                
                  (us) => us[tag].toLowerCase() === values.toLowerCase()
                  );  
            } else {
                 searchUsers = userData.filter(
                    (us) => us[tag].toLowerCase().indexOf(values.toLowerCase()) > -1
                  );
            }
            this.setState({ usersList: searchUsers});
          } else {
            this.setState({ usersList: userData});
          }
    }


    /**
     * handleAddressFilter method is used to filter on address
     */
    handleAddressFilter = (tag, values, type) => {
        if (values !== "") {
            let searchAddress = []
            /**
             * it will check if filter is apply from select than it will match exact keyword
             * else it will find nearest word
             */
            if(type === 'select') {
                 searchAddress = addressData.filter(
                
                  (us) => us[tag].toLowerCase() === values.toLowerCase()
                  );  
            } else {
                 searchAddress = addressData.filter(
                    (us) => us[tag].toLowerCase().indexOf(values.toLowerCase()) > -1
                  );
            }
            this.setState({ addressList: searchAddress});
          } else {
            this.setState({ addressList: addressData});
          }
    }


    /**
     * handleModalOpen is used to open modal or close
     */
    handleModalOpen = (tag) => {
        this.setState({
            tag: tag,
            isModalOpen: !this.state.isModalOpen
        })
    }
}

export default Clinic