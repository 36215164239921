import React from "react";
import { Formik, Field, ErrorMessage } from "formik";
import ReCAPTCHA from "react-google-recaptcha";

import logo from "../../../assets/images/bl-reach-logo.png";
import file_upload_ic from "../../../assets/images/file-upload-ic.svg";
import close_icon from "../../../assets/images/close_icon.svg";

import "./registration.css";

class ClinicRegistrationForm extends React.Component {
	state = {
		initialValues: {
			territoryManager: "",
			practiceBillTo: "",
			locationShipTo: "",
			practiceName: "",
			practiceAddress: {
				line1: "",
				line2: "",
				city: "",
				state: "",
				zipCode: "",
			},
			practiceContact: {
				firstName: "",
				lastName: "",
				address1: "",
				address2: "",
			},
			logo: null,
			termCondition: false,
		},
	};
	render() {
		return (
			<>
				<Header />
				<section className='inner-banner'>
					<h1>practice Registration Form</h1>
					<p>
						Enter your practice info below to be setup in the new Bausch + Lomb REACH Marketing Portal
					</p>
				</section>
				<section className='registration-form-sec'>
					<div className='registration-form-main'>
						<FormContent initialValues={this.state.initialValues} {...this.props} />
					</div>
				</section>
				<footer class="site-footer">
					<div className="footer-main">
					<div class="footer-left">
					<p>Copyright © 2020 <a href="#" title="CoBrand Media">CoBrand Media</a>. All Rights Reserved.</p>
					</div>
					<div class="footer-right">
						<ul><li><a href="#" title="Terms & Conditions">Terms & Conditions</a></li>						<li><a href="#" title="Contact Us">Contact Us</a></li>
						</ul></div></div></footer>
			</>
		);
	}
}

const Header = (props) => {
	return (
		<>
			<header className='header-logo'>
				<a href='#!' title='Bausch n Lomb'>
					<img src={logo} alt='logo' />
				</a>
			</header>
		</>
	);
};

const FormContent = (props) => {
	const initialValues = {
		territoryManager: "",
		practiceBillTo: "",
		locationShipTo: "",
		practiceName: "",
		practiceAddress: {
			line1: "",
			line2: "",
			city: "",
			state: "",
			zipCode: "",
		},
		practiceContact: {
			firstName: "",
			lastName: "",
			address1: "",
			address2: "",
		},
		logo: null,
		termCondition: "",
	};
	return (
		<Formik
			initialValues={initialValues}
			onSubmit={(values) => {
				console.log(values);
				props.history.push('/thank-you')
			}}>
			{({ handleSubmit, values, setFieldValue }) => {
				return (
					<form onSubmit={handleSubmit}>
							<div className='registration-form-wrap'>
								<div className='form_field'>									
									<label>Your Bausch + Lomb Representative</label>
									<input type="text" name="textbox" />								
								</div>
								<div className="form_field_half">
								<div className='form_field'>									
									<label>Ship To # <span>*</span></label>
									<input type="text" name="textbox" />
									<p><small>8 digits - add leading zeroes</small></p>								
								</div>
								<div className='form_field'>									
									<label>Practice Name <span>*</span></label>
									<input type="text" name="textbox" />								
								</div>
								</div>	
													
							</div>
						<div className='registration-form-wrap'>
							<div className='form_field'>
								<div className='field-left'>
									<label>
										Vision Care Territory Manager <span>*</span>
									</label>
								</div>
								<div className='field-right'>
									<select
										value={values.territoryManager}
										name='territoryManager'
										onChange={(e) => {
											setFieldValue("territoryManager", e.target.value);
										}}>
										<option value=''>Select your sales representative</option>
										<option value=''>Select your sales representative</option>
										<option value=''>Select your sales representative</option>
									</select>
								</div>
							</div>
							<div className='form_field'>
								<div className='field-left'>
									<label>
										Practice Bill To # <span>*</span>
									</label>
								</div>
								<div className='field-right'>
									<Field
										type='text'
										name='practiceBillTo'
										value={values.practiceBillTo}
									/>
									<p>
										<small>8 digits - add leading zeroes</small>
									</p>
								</div>
							</div>
							<div className='form_field'>
								<div className='field-left'>
									<label>
										Practice Location/Ship To # <span>*</span>
									</label>
								</div>
								<div className='field-right'>
									<Field
										type='text'
										name='locationShipTo'
										value={values.locationShipTo}
									/>
									<p>
										<small>8 digits - add leading zeroes</small>
									</p>
								</div>
							</div>
							<div className='form_field'>
								<div className='field-left'>
									<label>
										Practice Name <span>*</span>
									</label>
								</div>
								<div className='field-right'>
									<Field
										type='text'
										name='practiceName'
										value={values.practiceName}
									/>
								</div>
							</div>
						</div>
						<div className='registration-form-wrap'>
							<div className='form_field'>
								<div className='field-left'>
									<label>
										Practice Address <span>*</span>
									</label>
								</div>
								<div className='field-right'>
									<div className='field-field-row'>
										<Field
											type='text'
											name='practiceAddress.line1'
											value={values.practiceAddress.line1}
										/>
										<p>
											<small>Street Address</small>
										</p>
									</div>
									<div className='field-field-row'>
										<Field
											type='text'
											name='practiceAddress.line2'
											value={values.practiceAddress.line2}
										/>
										<p>
											<small>Street Address Line 2</small>
										</p>
									</div>

									<div className='field-field-row-wrap'>
										<div className='field-field-col'>
											<Field
												type='text'
												name='practiceAddress.city'
												value={values.practiceAddress.city}
											/>
											<p>
												<small>City</small>
											</p>
										</div>
										<div className='field-field-col'>
											<select
												value={values.practiceAddress.state}
												name='practiceAddress.state'
												onChange={(e) => {
													setFieldValue(
														"practiceAddress.state",
														e.target.value
													);
												}}>
												<option value=''>Select State</option>
												<option value='1'>Gujarat</option>
											</select>
											<p>
												<small>State</small>
											</p>
										</div>
										<div className='field-field-col'>
											<Field
												type='text'
												name='practiceAddress.zipCode'
												value={values.practiceAddress.zipCode}
											/>
											<p>
												<small>Zip Code</small>
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className='registration-form-wrap'>
							<div className='form_field'>
								<div className='field-left'>
									<label>
										Practice Contact <span>*</span>
									</label>
								</div>
								<div className='field-right'>
									<div className='field-field-row-wrap'>
										<div className='field-field-col'>
											<Field
												type='text'
												name='practiceContact.firstName'
												value={values.practiceContact.firstName}
											/>
											<p>
												<small>First Name</small>
											</p>
										</div>
										<div className='field-field-col'>
											<Field
												type='text'
												name='practiceContact.lastName'
												value={values.practiceContact.lastName}
											/>
											<p>
												<small>Last Name</small>
											</p>
										</div>
									</div>
								</div>
							</div>

							<div className='form_field'>
								<div className='field-left'>
									<label>
									Practice Phone Number <span>*</span>
									</label>
								</div>
								<div className='field-right'>
									<div className='field-field-row'>
										<Field
											type='text'
											name='practiceContact.address1'
											value={values.practiceContact.address1}
										/>
									</div>
								</div>
							</div>
							<div className='form_field'>
								<div className='field-left'>
									<label>
									Email Address <span>*</span>
									</label>
								</div>
								<div className='field-right'>
									<div className='field-field-row'>
										<Field
											type='text'
											name='practiceContact.address2'
											value={values.practiceContact.address2}
										/>
									</div>
								</div>
							</div>
							<div className='form_field'>
								<div className='field-left'>
									<label>
										Upload Logo <span>*</span>
									</label>
								</div>
								<div className='field-right'>
									<div className='form-file-input'>
										<input
											className='input-file'
											id='fileupload'
											name='files'
											type='file'
											onChange={(e) => {
												const file = e.target.files[0];
												if (
													file &&
													(file.type.includes("svg") ||
														file.type.includes("png") ||
														file.type.includes("jpeg") ||
														file.type.includes("jpg"))
												) {
													console.log("as");
													setFieldValue("logo", file);
												}
											}}
										/>
										<label htmlFor='fileupload' className='input-file-trigger'>
											<img src={file_upload_ic} alt='file-uplaod' />
											Drag and drop or Click here to upload file
										</label>
									</div>
									<p>
										Max logo size: 160X160 px. Accepted files : .jpg, .png, .svg
									</p>
									{values.logo && (
										<div className='logo-preview'>
											<a href="#" title="" className="close-ic"><img src={close_icon} alt="close-ic" /></a>
											<img
												src={window.URL.createObjectURL(
													new Blob([values.logo], { type: values.logo.type })
												)}
												alt='logo'
											/>
										</div>
									)}
								</div>
							</div>
							<div className='form_field'>
								<div className='field-left'>
									<label>
										Please check the box below to complete your registration.{" "}
										<span>*</span>
									</label>
								</div>
								<div className='field-right'>
									<div className="captcha-code">
									<ReCAPTCHA
										sitekey='6Lfe5f8UAAAAADJloOnK6Xki80E12hDTOW250Zlj'
										onChange={(value) => {
											setFieldValue("recaptcha", value);
										}}
									/>
									</div>
								</div>
							</div>
							<div className='form_field'>
								<div className='field-right'>
									<div className='custom-checkbox'>
										<Field
											type='checkbox'
											name='termCondition'
											id='term-condi'
											checked={values.termCondition === "yes"}
											onChange={(e) => {
												if (e.target.checked) {
													setFieldValue("termCondition", "yes");
												} else {
													setFieldValue("termCondition", "no");
												}
											}}
										/>
										<label htmlFor='term-condi'>
											Please confirm that you agree to the{" "}
											<a href='#!' title='terms & conditions'>
												terms & conditions.
											</a>
										</label>
									</div>
									<div className='button-field'>
										<input type='submit' value='submit' name='submit' />
									</div>
								</div>
							</div>
						</div>
					</form>
				);
			}}
		</Formik>
	);
};

export default ClinicRegistrationForm;
