import React from 'react';
import Slider from 'react-slick'


const TabComponent = props => {

    const {classes, className, tabs} = props;
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        arrows: true,
        nav: true,
        variableWidth: true,
        responsive: [
            {
              breakpoint: 1300,
              settings: {
                slidesToShow: 4,
                variableWidth: true,
              }
            },
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 3,
                variableWidth: true
              }
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                variableWidth: true
              }
            }
          ]
    }
    return (
        <div className={classes.root}>
            <ul>
                <Slider {...settings}>
                    {tabs.map((obj, index) => {
                        return <li key={index} onClick={() => props.setTab(index)}>
                        <a href="#!" title={obj} className={`${props.activeTab === index ? 'active': ''}`} >{obj}</a>
                    </li>
                    })}

                </Slider>
            </ul>
            <span className="tab-arrow"></span>
        </div>
    )
}

export default TabComponent;